import React, { useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/compat/app';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const PDFJS = window.pdfjsLib;

const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  },
}));

function Index (props){

  const classes = useStyles();
  const [ordersDetails, setOrdersDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [images, setImages] = React.useState([]);
  const [disableDownload, setDisableDownload] = React.useState(true);

  useEffect(() => {

    if(props.ordersDetails){
  
        setLoading(false)
        setOrdersDetails(props.ordersDetails)

        if(props.ordersDetails.pre_repair_image && props.ordersDetails.pre_repair_image.type === "application/pdf"){
          
          downloadFile(props.ordersDetails.pre_repair_image.url ? props.ordersDetails.pre_repair_image.url : null)

        }
        else{

          if(props.ordersDetails.pre_repair_image.url)
          {
            const imagesList = [];

            var request = new XMLHttpRequest();
            request.open('GET', props.ordersDetails.pre_repair_image.url, true);
            request.responseType = 'blob';
            request.onload = function() {
                var reader = new FileReader();
                reader.readAsDataURL(request.response);
                reader.onload =  function(e){
    
                    imagesList.push(e.target.result);
                    setImages(imagesList);
                    setDisableDownload(false)
                };
            };
            request.send();
          }
          
        }
        
    }

    
  }, [props]);

  const convertPdfToImages = async (file) => {
    const imagesList = [];
    const pdf = await PDFJS.getDocument(file).promise;
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    let canv = document.querySelector(".canv");

    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport
      };

      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
    setDisableDownload(false)
  }

  const downloadFile = (pdfUrl) => {
    if(pdfUrl){

      var request = new XMLHttpRequest();
        request.open('GET', pdfUrl, true);
        request.responseType = 'blob';
        request.onload = function() {
            var reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onload =  function(e){
                //console.log('DataURL:', e.target.result);

                convertPdfToImages(e.target.result)
            };
        };
        request.send();
      

    }
    
  };
  

  const onError = (e)=>{
    console.log(e)
  }

  const onClickDownloadPDF = ()=>{
    setDisableDownload(true)
    var pdf = new jsPDF('p', 'pt', [1240, 1754]);
    var pageNumCount = 0;

    const quotes  = document.getElementById('divToPrint');
    html2canvas(quotes , { scale:1 })
      .then((canvas) => {
        

        for (var i = 0; i <= (quotes.clientHeight/1754 - 1); i++) {
            //! This is all just html2canvas stuff
            var srcImg  = canvas;
            var sX      = 0;
            var sY      = 1754*i; // start 980 pixels down for every new page
            var sWidth  = 1240;
            var sHeight = 1754;
            var dX      = 0;
            var dY      = 0;
            var dWidth  = 1240;
            var dHeight = 1754;

            const onePageCanvas  = document.createElement("canvas");
            onePageCanvas.setAttribute('width', 1240);
            onePageCanvas.setAttribute('height', 1754);
            var ctx = onePageCanvas.getContext('2d');
            // details on this usage of this function: 
            // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
            ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);

            // document.body.appendChild(canvas);
            var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

            var width         = onePageCanvas.width;
            var height        = onePageCanvas.clientHeight;
            

            //! If we're on anything other than the first page,
            // add another page
            if(pageNumCount > 0) {
                pdf.addPage(); //8.5" x 11" in pts (in*72)
            }

            pageNumCount++;
            pdf.setPage(pageNumCount+1);
            //! now we add content to that page!
            pdf.addImage(canvasDataURL, 'PNG', 0, 0, (width), (height));

        }
        
        const quotes1  = document.getElementById('divToPrint1');
        html2canvas(quotes1 , { scale:1 })
        .then((canvas) => {

          for (var i = 0; i <= (quotes1.clientHeight/1754 - 1); i++) {
            //! This is all just html2canvas stuff
            var srcImg  = canvas;
            var sX      = 0;
            var sY      = 1754*i; // start 980 pixels down for every new page
            var sWidth  = 1240;
            var sHeight = 1754;
            var dX      = 0;
            var dY      = 0;
            var dWidth  = 1240;
            var dHeight = 1754;

            const onePageCanvas  = document.createElement("canvas");
            onePageCanvas.setAttribute('width', 1240);
            onePageCanvas.setAttribute('height', 1754);
            var ctx = onePageCanvas.getContext('2d');
            // details on this usage of this function: 
            // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
            ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);

            // document.body.appendChild(canvas);
            var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

            var width         = onePageCanvas.width;
            var height        = onePageCanvas.clientHeight;
            

            //! If we're on anything other than the first page,
            // add another page
            if(pageNumCount > 0) {
                pdf.addPage(); //8.5" x 11" in pts (in*72)
            }

            pageNumCount++;
            pdf.setPage(pageNumCount+1);
            //! now we add content to that page!
            pdf.addImage(canvasDataURL, 'PNG', 0, 0, (width), (height));

        }

          const quotes2  = document.getElementById('divToPrint2');
          html2canvas(quotes2 , { scale:1 })
          .then((canvas) => {

            for (var i = 0; i <= (quotes2.clientHeight/1754 - 1); i++) {
              //! This is all just html2canvas stuff
              var srcImg  = canvas;
              var sX      = 0;
              var sY      = 1754*i; // start 980 pixels down for every new page
              var sWidth  = 1240;
              var sHeight = 1754;
              var dX      = 0;
              var dY      = 0;
              var dWidth  = 1240;
              var dHeight = 1754;
  
              const onePageCanvas  = document.createElement("canvas");
              onePageCanvas.setAttribute('width', 1240);
              onePageCanvas.setAttribute('height', 1754);
              var ctx = onePageCanvas.getContext('2d');
              // details on this usage of this function: 
              // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
              ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);
  
              // document.body.appendChild(canvas);
              var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);
  
              var width         = onePageCanvas.width;
              var height        = onePageCanvas.clientHeight;
              
  
              //! If we're on anything other than the first page,
              // add another page
              if(pageNumCount > 0) {
                  pdf.addPage(); //8.5" x 11" in pts (in*72)
              }
  
              pageNumCount++;
              pdf.setPage(pageNumCount+1);
              //! now we add content to that page!
              pdf.addImage(canvasDataURL, 'PNG', 0, 0, (width), (height));
  
          }

            pdf.save('Incident_Report-'+ props.ordersDetails.claim_id + '.pdf');
            setDisableDownload(false)
          });
        });
        

      })
    ;
  }


  if(loading){
    return (
    <Grid className={classes.root}>
      <Grid style={{ marginTop: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>
    </Grid>);
  }


  return (
    <Paper elevation={0} style={{ marginBottom: 50 }}>
      <Grid>
      <Button disabled={disableDownload} variant="contained" color="primary" disableElevation onClick={()=> onClickDownloadPDF()}>
        Download PDF
      </Button>
      <div id="divToPrint" style={{ backgroundColor: '#f5f5f5',
        width: 1240,
        minHeight: 1754,
        marginLeft: 'auto',
        marginRight: 'auto' }}>
        <Grid style={{ width: "100%", flexDirection: "row", paddingBottom: 20, padding: 30 }}>
          <Grid style={{ padding: 15, display: "flex", flexDirection: "column" }}>
            <Grid>
            
            
            <Grid style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <img height={120} src="/assets/logo/logo-dark.png" alt="Sp2s Logo"/>
            </Grid>
              
            </Grid>
          
            
            </Grid>


            <Grid style={{ paddingTop: 10, display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
              <Grid>
                <Typography align='center' variant="h4" style={{ fontWeight: "bold", color: "black" }}>
                      {"INCIDENT REPORT"}
                    </Typography>
              </Grid>
                    
            </Grid>

            

            <Grid style={{ paddingTop: 20, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Grid style={{ display: "flex", flexDirection: "column" }} >
                <Grid>
                  <Typography variant="caption"  style={{ fontWeight: "normal", color: "black" }}>
                    {"Date"}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="body2" style={{ fontWeight: "normal", color: "black" }}>
                        {new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long', hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(ordersDetails.created? ordersDetails.created : "")}
                      </Typography>
                </Grid>
                      
              </Grid>

            </Grid>
            
          </Grid>

          <Divider light style={{ width: "100%"}} />

          <Grid style={{ padding: 15, display: "flex", flexDirection: "column" }}>
          <Grid container style={{ display: "flex", flexDirection: "row" }}>
              <Grid item xs={6} style={{ display: "flex", flexDirection: "column" }} >
                <Grid>
                  <Typography variant="h5"  style={{ fontWeight: "bold", color: "black" }}>
                    {"Customer Details"}
                  </Typography>
                </Grid>
                      
              </Grid>

              <Grid item xs={6} >
                <Typography variant="h5" style={{ fontWeight: "bold", color: "black" }}>
                  {"Device Details"}
                </Typography>
              </Grid>

            </Grid>
            </Grid>

          <Divider light style={{ width: "100%"}} />

          <Grid container style={{ padding: 15 }}>
            <Grid item xs={6} style={{ display: "flex", flexDirection: "column" }}>
                <Grid>
                  <Grid>
                    <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }}>
                      {"Name"}
                    </Typography>
                    <Typography variant="body2"  style={{ fontWeight: "normal", color: "black" }}>
                      {ordersDetails.customer_name? ordersDetails.customer_name  : "N/A"}
                    </Typography>
                  </Grid>
                
                </Grid>

                {ordersDetails.physical_device_model_no ? <Grid style={{ paddingTop: 10 }}>
                  <Grid>
                    <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }}>
                      {"Model"}
                    </Typography>
                    <Typography variant="body2"  style={{ fontWeight: "normal", color: "black" }}>
                      {ordersDetails.physical_device_model_no? ordersDetails.physical_device_model_no  : "N/A"}
                    </Typography>
                  </Grid>
                
                </Grid> : null }
                
                {ordersDetails.customer_email? <Grid style={{ paddingTop: 10 }}>
                  <Grid>
                    <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }}>
                      {"Email"}
                    </Typography>
                    <Typography variant="body2"  style={{ fontWeight: "normal", color: "black" }}>
                      {ordersDetails.customer_email? ordersDetails.customer_email  : "N/A"}
                    </Typography>
                  </Grid>
                
                </Grid> : null }

                {ordersDetails.customer_phone? <Grid style={{ paddingTop: 10 }}>
                  <Grid>
                    <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }}>
                      {"Phone"}
                    </Typography>
                    <Typography variant="body2"  style={{ fontWeight: "normal", color: "black" }}>
                      {ordersDetails.customer_phone? ordersDetails.customer_phone  : "N/A"}
                    </Typography>
                  </Grid>
                
                </Grid> : null }
              
            </Grid>
            <Grid item xs={6} style={{ display: "flex", flexDirection: "column" }}>
                <Grid>
                  <Grid>
                    <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }}>
                      {"Claim ID"}
                    </Typography>
                    <Typography variant="body2"  style={{ fontWeight: "normal", color: "black" }}>
                      {ordersDetails.claim_id? ordersDetails.claim_id  : "N/A"}
                    </Typography>
                  </Grid>
                
                </Grid>

                {ordersDetails.physical_device_brand ? <Grid style={{ paddingTop: 10 }}>
                  <Grid>
                    <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }}>
                      {"Brand"}
                    </Typography>
                    <Typography variant="body2"  style={{ fontWeight: "normal", color: "black" }}>
                      {ordersDetails.physical_device_brand? ordersDetails.physical_device_brand  : "N/A"}
                    </Typography>
                  </Grid>
                
                </Grid> : null }
                
                {ordersDetails.physical_device_serial_no? <Grid style={{ paddingTop: 10 }}>
                  <Grid>
                    <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }}>
                      {"Serial No."}
                    </Typography>
                    <Typography variant="body2"  style={{ fontWeight: "normal", color: "black" }}>
                      {ordersDetails.physical_device_serial_no? ordersDetails.physical_device_serial_no  : "N/A"}
                    </Typography>
                  </Grid>
                
                </Grid> : null }

                {ordersDetails.imei_no? <Grid style={{ paddingTop: 10 }}>
                  <Grid>
                    <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }}>
                      {"IMEI"}
                    </Typography>
                    <Typography variant="body2"  style={{ fontWeight: "normal", color: "black" }}>
                      {ordersDetails.imei_no? ordersDetails.imei_no  : "N/A"}
                    </Typography>
                  </Grid>
                
                </Grid> : null }

                {ordersDetails.service_plan_purchase_date? <Grid style={{ paddingTop: 10 }}>
                  <Grid>
                    <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }}>
                      {"Purchase Date"}
                    </Typography>
                    <Typography variant="body2"  style={{ fontWeight: "normal", color: "black" }}>
                      {ordersDetails.service_plan_purchase_date? ordersDetails.service_plan_purchase_date  : "N/A"}
                    </Typography>
                  </Grid>
                
                </Grid> : null }
              
            </Grid>
          </Grid>

          <Divider light style={{ width: "100%"}} />

          <Grid style={{ padding: 15, display: "flex", flexDirection: "column" }}>
          <Grid container style={{ display: "flex", flexDirection: "row" }}>
              <Grid item xs={6} style={{ display: "flex", flexDirection: "column" }} >
                <Grid>
                  <Typography variant="h6"  style={{ fontWeight: "bold", color: "black" }}>
                    {"Description Nature Of Damage"}
                  </Typography>
                </Grid>
                      
              </Grid>

            </Grid>

            <Typography variant="body2"  style={{ fontWeight: "normal", color: "black" }}>
                      {ordersDetails.incident_detail? ordersDetails.incident_detail  : "N/A"}
                    </Typography>
          </Grid>

          

            
            
        </Grid>
      </div>
      <div id="divToPrint1" style={{ backgroundColor: '#f5f5f5',
        width: 1240,
        minHeight: 1754,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: "flex", 
          flexDirection: "column", justifyContent: "flex-start" }}>
            <Grid style={{ width: "100%", paddingBottom: 20, padding: 30 }}>
              <Grid style={{ padding: 15, display: "flex", flexDirection: "column" }}>
                    
                    <Grid style={{ padding: 15, display: "flex", flexDirection: "column" }}>
                    <Grid container style={{ display: "flex", flexDirection: "row" }}>
                        <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }} >
                          <Grid>
                            <Typography variant="h6"  style={{ fontWeight: "bold", color: "black" }}>
                              {"Proof of Damage Device"}
                            </Typography>
                          </Grid>
                                
                        </Grid>

                        <Grid item xs={12} style={{ paddingTop: 10, display: "flex", flexDirection: "column" }} >

                          <Grid container>
                              {images.map((image, idx) => (
                                <Grid item key={idx} xs={4}>
                                  <img
                                    id="image-generated"
                                    src={image}
                                    alt="pdfImage"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      margin: "0",
                                      border: "none"
                                    }}
                                  />
                                </Grid>
                              ))}
                              </Grid>
                          </Grid>

                      </Grid>
                      </Grid>

                      <Divider light style={{ width: "100%"}} />

              </Grid>
            </Grid>
          </div>
      <div id="divToPrint2" style={{ backgroundColor: '#f5f5f5',
         width: 1240,
         minHeight: 1754,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: "flex", 
          flexDirection: "column", justifyContent: "flex-end" }}>

          <Grid style={{ width: "100%", paddingBottom: 20, padding: 30 }}>
            <Grid>
            <Grid style={{ padding: 15, display: "flex", flexDirection: "column" }}>
            <Grid container style={{ display: "flex", flexDirection: "row" }}>
                <Grid item xs={12} style={{ paddingTop: 50, display: "flex", flexDirection: "column" }} >
                  <Grid>
                    <Typography variant="caption"  style={{ fontWeight: "bold", color: "black" }}>
                      {"Signature : "}
                    </Typography>
                  </Grid>
                        
                </Grid>

                <Grid item xs={12} style={{ paddingTop: 20, display: "flex", flexDirection: "column" }} >
                  <Grid>
                    <Typography variant="caption"  style={{ fontWeight: "bold", color: "black" }}>
                      {"Date : "}
                    </Typography>
                  </Grid>
                        
                </Grid>

              </Grid>
              </Grid>


            <Divider light style={{ width: "100%"}} />
              <Grid>
                  <Typography variant="h6" style={{ paddingTop: 20, fontSize: 14, fontWeight: "bold", color: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {"THANK YOU FOR YOUR BUSINESS!"}
                      </Typography>
                </Grid>
                
                <Grid>
                <Typography variant="caption" style={{ paddingTop: 10, fontSize: 14, fontWeight: "bold", color: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {"- instaprotection.sg -"}
                </Typography>
                </Grid>
                <Grid>
                  <Typography variant="h6" style={{ paddingTop: 10, fontSize: 10, fontWeight: "normal", color: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {"** Goods sold are not exchangeable **"}
                  </Typography>
                </Grid>
              </Grid>
          </Grid>

        </div>
      </Grid>
      
    </Paper>
  );
  
}

export default Index;