import CartActionTypes from './cart.types'

export const toggleCartHidden = () => ({
    type: CartActionTypes.TOGGLE_CART_HIDDEN
})

export const setItem = (item, user) => ({
    type: CartActionTypes.SET_ITEM,
    payload: item,
    user: user
})

export const addItem = (item, user) => ({
    type: CartActionTypes.ADD_ITEM,
    payload: item,
    user: user
})

export const changeItemQuantity = (quantity, item, user) => ({
    type: CartActionTypes.CHANGE_ITEM_QUANTITY,
    payload: item,
    quatity: quantity,
    user: user
})

export const clearItemFromCart = (item, user) => ({
    type: CartActionTypes.CLEAR_ITEM_FROM_CART,
    payload: item,
    user: user
})


export const removeItem = (item, user)=>({
    type: CartActionTypes.REMOVE_ITEM,
    payload: item,
    user: user
})
