import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '../../../modules/components/Dialog';
import Typography from '@material-ui/core/Typography';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import firebase from 'firebase/compat/app';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #6e6e6e',
    width: 70,
    height: 70,
    padding: 4,
    boxSizing: 'border-box',
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8 
};

 
const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

export default function Index(props) {

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");
    const [values, setValues] = React.useState({});
    const [serviceCentre, setServiceCentre] = React.useState([]);

    useEffect(() => {
        setValues(props.addCellsName ? props.addCellsName : {})
        
        if(props.option === "edit"){
            setValues(props.selectedItem ? props.selectedItem : {})
        }

        let serviceAllRef = firebase.database().ref("service_centre");

        serviceAllRef.once('value', snapshot => {

            var _serviceCentre = [];

            if(snapshot.exists()){

                snapshot.forEach(child=>{
                    var item = child.val();
                    _serviceCentre.push(item);
                })
                setServiceCentre(_serviceCentre);
            }
        })

    }, [props]);

    const handleClose = () => {
        setOpenDialog(false)
    };

    const saveUser = (uuid) => {

        var updates = {};
        values.id = "IP" + parseInt(uuid).toLocaleString('en-US', {minimumIntegerDigits: 6, useGrouping:false});

        updates[props.data + '/' + values.id] = values;

        firebase.database().ref().update(updates).then(()=>{
            props.handleClose()
        })
          
    }

    const handleAgree = () => {

        var updates = {};
        if(props.option === "edit"){
            values.id = props.selectedItem.id;
            updates[props.data + '/' + props.selectedItem.id] = values;
    
            firebase.database().ref().update(updates);

            props.handleClose()
        }
        else{

            let areaUUIDRef = firebase.database().ref("user_sequence" )

            areaUUIDRef.once('value', snapshot => {
            if(snapshot.exists()){
                let val = snapshot.val()

                let newUUID = Number(val.uuid)+1
                saveUser(newUUID)
                firebase.database().ref("user_sequence").update({ uuid: newUUID })
            }
            else{
                saveUser(1)
                firebase.database().ref("user_sequence").update({ uuid: 1 })
            }

            })

           
        }
        

        
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };


    return (
        <Grid style={{ maxWidth: 625  }}>
            <Dialog 
                agreeTxt={"Yes"}
                disagreeTxt={"No"}
                description={dialogDes}
                title={dialogTitle}
                open={openDialog} 
                agreeButton={agreeButton}
                fullScreenDialog={fullScreenDialog}
                option={option}
                dialogLoading={dialogLoading}
                handleClose={handleClose}/>

            <Grid container style={{ paddingBottom: 10, display: "flex", flexDirection: "row" }}>
                {props[props.option === "edit" ? "editCells" : "addCells"] ? props[props.option === "edit" ? "editCells" : "addCells"].map((cell, index)=>{ 
                const labelCellId = `add-cell-${index}`;
                var sizeMd = 6;

                if(index === props.addCells.length - 1){
                    if((index+1) % 2 !== 0){
                    sizeMd = 12;
                    } 
                }

                if(cell.id === "role"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values[cell.id] || ''}
                            label="Role"
                            onChange={handleChange(cell.id)}
                            >
                            <MenuItem value={"staff"}>Staff</MenuItem>
                            <MenuItem value={"manager"}>Manager</MenuItem>
                            <MenuItem value={"admin_assistance"}>Admin Assistance</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>)
                }

                if(cell.id === "status_users"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values[cell.id] || ''}
                            label="Status"
                            onChange={handleChange(cell.id)}
                            >
                            <MenuItem value={"new_user"}>New User</MenuItem>
                            <MenuItem value={"active"}>Active</MenuItem>
                            <MenuItem value={"inactive"}>inactive</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>)
                }

                if(cell.id === "service_center_id"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Service Center</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values[cell.id] || ''}
                            label="Service Center"
                            onChange={handleChange(cell.id)}
                            >
                                {serviceCentre.map(val => {
                                    return(
                                        <MenuItem value={val.id}>{val.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>)
                }
                
                
                return(   
                    <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                    <TextField
                        key={"textfield-input-"+index}
                        label={cell.label}
                        style={{ width: "100%"}}
                        value={values[cell.id] || ''}
                        onChange={handleChange(cell.id)}
                        variant="outlined"/></Grid>
                    )}):null }
            </Grid>
            
            <Grid style={{ padding: 10, marginBottom: 30, display: 'flex', justifyContent: "flex-end" }}>
                <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
                {"No"}
                </Button>
                <Button disableElevation variant="contained" style={{ marginLeft: 10, fontSize: 14, fontWeight: 'bold'}} onClick={handleAgree} color="primary" autoFocus>
                {"Yes"}
                </Button>
            </Grid>
        </Grid>
    )

}
  