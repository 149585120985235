import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddEditEW from './AddEditEW';
import firebase from 'firebase/compat/app';

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const [excelList, setExcelList] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        

        setHeadCells([
            { id: 'imei_no', label: 'IMEI No.' },
            { id: 'claim_id', label: 'Claim ID' },
            { id: 'created', label: 'Created' },
            { id: 'customer_name', label: 'Customer Name' },
            { id: 'customer_phone', label: 'Phone' },
            { id: 'status', label: 'Status' },
            { id: 'fully_submitted', label: 'Fully submitted' },
            { id: 'claim_quantity', label: 'Claimed' },
            { id: 'submit_user', label: 'Submitted User' },
        ])

        setHeadMobileCells([
            { id: 'imei_no', numeric: false, disablePadding: false, label: 'IMEI No.' },
        ])
    
        setSearchCell([
            { id: 'imei_no', label: 'IMEI No.' },
            { id: 'customer_name', label: 'Customer Name' },
            { id: 'customer_phone', label: 'Phone' },
        ])

        setDisplayInfo([
            { id: 'imei_no', label: 'IMEI No.' },
            { id: 'claim_id', label: 'Claim ID' },
            { id: 'submit_user', label: 'Submitted User' },
            { id: 'status', label: 'Status' },
            { id: 'created', label: 'Created' },
            { id: 'customer_name', label: 'Customer Name' },
            { id: 'customer_phone', label: 'Phone' },
            { id: 'customer_email', label: 'Customer Email' },
            { id: 'service_plan_purchase_date', label: 'Purchase Date' },
            { id: 'purchase_invoice', label: 'Purchase Invoice' },
            { id: 'incident_date', label: 'Incident Date / Time' },
            { id: 'incident_location', label: 'Incident Location' },
            { id: 'incident_detail', label: 'Incident Detail' },
            { id: 'pre_repair_image', label: 'Pre Repair Image' },
            { id: 'repair_date', label: 'Repair Date' },
            { id: 'repair_amount', label: 'Repair Amount' },
            { id: 'service_center_name', label: 'Service Center' },
            { id: 'post_repair_image', label: 'Post Repair Image' },
            { id: 'repair_invoice', label: 'Repair Invoice' },
            { id: 'service_repair_report', label: 'Service Repair Report' },
            { id: 'fully_submitted', label: 'Fully submitted' },
            { id: 'claim_quantity', label: 'Time of Claimed' },
            
        ])

        setExcelList([
            { id: 'imei_no', label: 'IMEI No.' },
            { id: 'claim_id', label: 'Claim ID' },
            { id: 'status', label: 'Status' },
            { id: 'created', label: 'Created' },
            { id: 'customer_name', label: 'Customer Name' },
            { id: 'customer_phone', label: 'Phone' },
            { id: 'customer_email', label: 'Customer Email' },
            { id: 'service_plan_purchase_date', label: 'Purchase Date' },
            { id: 'purchase_invoice', label: 'Purchase Invoice' },
            { id: 'incident_date', label: 'Incident Date / Time' },
            { id: 'incident_location', label: 'Incident Location' },
            { id: 'incident_detail', label: 'Incident Detail' },
            { id: 'pre_repair_image', label: 'Pre Repair Image' },
            { id: 'repair_date', label: 'Repair Date' },
            { id: 'repair_amount', label: 'Repair Amount' },
            { id: 'service_center_name', label: 'Service Center' },
            { id: 'submit_user', label: 'Submitted User' },
            { id: 'post_repair_image', label: 'Post Repair Image' },
            { id: 'repair_invoice', label: 'Repair Invoice' },
            { id: 'service_repair_report', label: 'Service Repair Report' },
            { id: 'fully_submitted', label: 'Fully submitted' },
            { id: 'physical_device_model_no', label: 'Phone Model' },
        ])

        setEditCells([
            { id: 'customer_name', label: 'Customer Name' },
            { id: 'customer_phone', label: 'Phone' },
            { id: 'customer_email', label: 'Customer Email' },
           
            { id: 'status', label: 'Status' },
            { id: 'email_description', label: 'Email Description' },
            
        ])

        setAddCells([
            { id: 'customer_name', label: 'Customer Name' },
            { id: 'customer_phone', label: 'Phone' },
            { id: 'customer_email', label: 'Customer Email' },
            { id: 'status', label: 'Status' },
        ])

        setAddCellsName({ 
            customer_name: '', 
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            customer_phone: ''
         })
    
        setSearchValue("imei_no")
        
    }, []);

    return (
    <Grid>
        <Table 
            control={true}
            hideDelete={true}
            disabledMove={true}
            customizeDialog={AddEditEW}
            orderBy="created" 
            displayAllEW={true}
            data={"ew_claimed"} 
            incidentReport={true}
            displayInfo={displayInfo}
            addCells={addCells}
            addCellsName={addCellsName}
            editCells={editCells}
            excelList={excelList}
            
            matches={matches}
            downloadExcel={firebase.auth().currentUser.displayName === "admin" ? true : false}
            hideAllDelete={firebase.auth().currentUser.displayName === "admin" ? false : true}
            addItemButton={firebase.auth().currentUser.displayName === "admin" ? true : false}
            editButton={firebase.auth().currentUser.displayName === "admin" ? true : false}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
  