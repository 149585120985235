import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import app from "../../firebase/base";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { selectCurrentUser } from '../../redux/user/user.selector'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PersonIcon from '@material-ui/icons/Person';

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeader2: {
    paddingTop: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    paddingTop: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: "black",
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  notificationIcon: {
    minWidth: 'auto',
    marginLeft: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: "white",
    opacity: ".2"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

function Navigator(props) {
  const { classes, staticContext,  ...other } = props;
  const path = props.location.pathname.slice(1);
  const [open, setOpen] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [staffNav ] = React.useState([
    {
      id: 'Manage',
      children: [
        { id: 'Search IMEI', icon: <VpnKeyIcon />, url: '', notification: 0 },
      ],
    }
  ]);

  const [managerNav ] = React.useState([
    {
      id: 'Manage',
      children: [
        { id: 'Search IMEI', icon: <VpnKeyIcon />, url: '', notification: 0 },
        { id: 'EW List', icon: <ListAltIcon />, url: 'ew', notification: 0 },
        { id: 'SC List', icon: <PhoneAndroidIcon />, url: 'screen_crack', notification: 0 },
        { id: 'Reg Staff', icon: <PersonIcon />, url: 'register_staff', notification: 0 },
      ],
    }
  ]);

  const [adminNav ] = React.useState([
    {
      id: 'Manage',
      children: [
        { id: 'Search IMEI', icon: <VpnKeyIcon />, url: '', notification: 0 },
        { id: 'All Staff', icon: <ListAltIcon />, url: 'all_staff', notification: 0 },
        { id: 'Submmited EW', icon: <ListAltIcon />, url: 'all_ew', notification: 0 },
        { id: 'Submmited SC', icon: <PhoneAndroidIcon />, url: 'all_sc', notification: 0 },
        { id: 'EW IMEI List', icon: <ListAltIcon />, url: 'ewlist', notification: 0 },
        { id: 'SC IMEI List', icon: <ListAltIcon />, url: 'sclist', notification: 0 },
      ],
    }
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignout = () => {

    setBackdropOpen(true)

    app.auth().signOut().then(() => {
      setBackdropOpen(false)
    }).catch((error) => {
      setBackdropOpen(false)
    });

    setOpen(false);
  };

  const renderNav = () => {

    if(props.currentUser && props.currentUser.role === "manager")
    {
      return managerNav
    }
    else if(props.currentUser && props.currentUser.role === "staff")
    {
      return staffNav
    }
    else if(props.currentUser && props.currentUser.role === "admin")
    {
      return adminNav
    }
    else if(props.currentUser && props.currentUser.role === "admin2")
    {
      return adminNav
    }
    else
    {
      return staffNav
    }

  }
  
  return (
    <div>
    <Backdrop className={classes.backdrop} open={backdropOpen}>
      <CircularProgress 
        disableShrink
        color="inherit" />
    </Backdrop>
    <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ fontSize: 24, fontWeight: 'bold'}}>Logout</Typography>
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description">
            You will be returned to the login screen
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={handleClose} color="primary">
            No 
          </Button>
          <Button disableElevation variant="contained" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={handleSignout} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
    </Dialog>
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem style={{ display:'flex', justifyContent: 'center'}}  className={clsx(classes.firebase, classes.itemCategory)}>
          <img height={80} src="/assets/logo/logo-white.png" alt="Insta Protection logo"/>
        </ListItem>

        <Divider className={classes.divider} />
        <ListItem  style={{ paddingBottom: 0, paddingTop: 15}}>
        <Typography variant='body1' style={{  color: "white", fontSize: 12 }}>
          User ID
        </Typography>
        </ListItem>

        <ListItem  style={{ paddingBottom: 0, paddingTop: 0}}>
        <Typography variant='body1' style={{  color: "white", fontSize: 16 }}>
        {props.currentUser &&  props.currentUser.id ? "" + 
            props.currentUser.id : "User ID No Found"}
        </Typography>
        </ListItem>

        <Divider className={classes.divider} />

        <ListItem  style={{ paddingBottom: 0, paddingTop: 15}}>
          <ListItemText
             className={clsx(classes.item, true && classes.itemActiveItem)}
             style={{ color: "white"}}
          >
            {props.currentUser &&  props.currentUser.first_name && props.currentUser.last_name ? "" + 
            props.currentUser.last_name + " " + props.currentUser.first_name +"": "User No Found"}
          </ListItemText>
        </ListItem>

        {props.currentUser &&  props.currentUser.role === "manager" ?<ListItem  style={{ paddingBottom: 0, paddingTop: 0}}>
        <Typography variant='body1' style={{  color: "white", fontSize: 12 }}>
         {"Manager"}
        </Typography>
        </ListItem> :  null }

        <Divider className={classes.divider} />
        
        {renderNav().map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, notification, url }) => (
              <ListItem
                key={childId}
                button
                className={path === url ? clsx(classes.item, true && classes.itemActiveItem) : clsx(classes.item, false && classes.itemActiveItem)}
                component={Link} to={'/' + url.toLowerCase()} 
                onClick={props.onClose}
              >
                
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>

                <ListItemIcon className={classes.notificationIcon}>{notification > 0 ? notification : ''}</ListItemIcon>
              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}

        <ListItem
          button
          style={{ marginTop: 10 }}
          className={clsx(classes.item, false && classes.itemActiveItem)}
          onClick={handleClickOpen}
        >
          <ListItemIcon className={classes.itemIcon}><ExitToAppIcon /></ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            {'Logout'}
          </ListItemText>
        </ListItem>
          </List>
    </Drawer>
    </div>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

const routeNavigator = withRouter(Navigator);

const stylerouteNavigator = withStyles(styles)(routeNavigator);

const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default connect(mapStatetoProps)(stylerouteNavigator);