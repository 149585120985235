import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import moment from "moment";
import { Tooltip, Input, InputLabel } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    display:"flex",
    width:"100%",
    justifyContent: "space-between",
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, data, ...other } = props;
  return (
    <React.Fragment>
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography style={{ color: "black", textAlign: "center", marginTop: 5}} variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon  style={{ color: "black  "}}/>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <Divider style={{ opacity: .2 }}/>
    </React.Fragment>
  );
});

export default function AlertDialog(props) {

  useEffect(() => {

  }, [props]);

  if(props.dialogFullLoading){
    return (
    <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
      <CircularProgress 
        disableShrink
        color="primary" />
    </div>);
  }

  return (
    <div >
      <Dialog
        fullScreen={props.fullScreenDialog}
        open={props.open}
        fullWidth={true}
        maxWidth={props.maxWidth ? props.maxWidth : "xs"}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            boxShadow: 'none',

          },
        }}
      >

        <DialogTitle style={{ minWidth: 200 }} id="customized-dialog-title" onClose={props.handleClose} data={props.data}>
          {props.title}
        </DialogTitle>

        {props.description !== "" ?
          <Typography style={{ padding: 18 }} id="alert-dialog-description">
            {props.description}
          </Typography>
        :null}
        {props.option=== "info" ? 
        <Table>
            <TableBody>
              {props.displayInfo.map((cell, index)=>{
                const labelCellId = `enhanced-table-cell-${index}`;
                
                if(cell.id === "created" || cell.id === "updated"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      {props.selectedItem[cell.id] ? <TableCell component="th" scope="row">
                      {new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' ,hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
                      .format(props.selectedItem[cell.id])}
                      </TableCell> : <TableCell component="th" scope="row"></TableCell>}
                    </TableRow>)
                }

                else if(cell.id === "pre_repair_image"
                || cell.id === "purchase_invoice"
                || cell.id === "post_repair_image"
                || cell.id === "repair_invoice"
                || cell.id === "service_repair_report"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      {props.selectedItem[cell.id] ? <TableCell component="th" scope="row">
                      <Link target="_blank" href={props.selectedItem[cell.id].url} >
                        {props.selectedItem[cell.id].name}
                      </Link>
                      </TableCell> : <TableCell component="th" scope="row"></TableCell>}
                    </TableRow>)
                }

                else if(cell.id === "incident_date" || cell.id === "repair_date"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      {props.selectedItem[cell.id] ? <TableCell component="th" scope="row">
                      {moment(props.selectedItem[cell.id]).format("DD-MM-YYYY HH:mm")}
                      </TableCell> : <TableCell component="th" scope="row"></TableCell>}
                    </TableRow>)
                }

                else if(cell.id === "service_plan_purchase_date" || cell.id === "sc_service_plan_purchase_date"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      {props.selectedItem[cell.id] ? <TableCell component="th" scope="row">
                      {moment(props.selectedItem[cell.id]).format("DD-MM-YYYY")}
                      </TableCell> : <TableCell component="th" scope="row"></TableCell>}
                    </TableRow>)
                }

                else if(cell.id === "repair_amount"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      {props.selectedItem[cell.id] ? <TableCell component="th" scope="row">
                      {"USD " + props.selectedItem[cell.id]}
                      </TableCell> : <TableCell component="th" scope="row"></TableCell>}
                    </TableRow>)
                }

                else if(cell.id === "fully_submitted"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      {props.selectedItem["repair_date"] ? <TableCell component="th" scope="row">
                      {"Yes"}
                      </TableCell> : <TableCell component="th" scope="row">{"No"}</TableCell>}
                    </TableRow>)
                }
                else if(cell.id === "submit_user"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      {props.selectedItem[cell.id] ? <TableCell component="th" scope="row">
                      {props.selectedItem[cell.id].id}
                      </TableCell> : <TableCell component="th" scope="row">{"-"}</TableCell>}
                    </TableRow>)
                }

                return(
                  <TableRow key={labelCellId} >
                    <TableCell component="th" scope="row">
                      {cell.label}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid style={{overflow: "hidden", maxWidth: 200}} >
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id]}</Typography>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  
                )
              })}
            </TableBody>
        </Table> : null}

        {props.option === "addItem" && props.customizeDialog ? <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><props.customizeDialog {...props}/></Grid> : null }
        {props.option === "edit" && props.customizeDialog ? <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><props.customizeDialog {...props}/></Grid> : null }

        {props.option === "edit" && !props.customizeDialog ? 
          <Grid container style={{  paddingBottom: 10, display: "flex", flexDirection: "row" }}>
            {props.editCells.map((cell, index)=>{ 
              const labelCellId = `edit-cell-${index}`;
              var sizeMd = 6;

              if(index === props.editCells.length - 1){
                if((index+1) % 2 !== 0){
                  sizeMd = 12;
                } 
              }

              return(
                <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                  <TextField
                    key={"textfield-input-"+index}
                    label={cell.label}
                    style={{ width: "100%"}}
                    value={props.selectedItem[cell.id]}
                    onChange={(e)=>props.onChangeHandle(e, cell.id)}
                    variant="outlined"/></Grid>
                    
                  )}) }
          </Grid>
        : null}

        {props.option === "uploadExcel" ? <Grid>
              {<InputLabel htmlFor="import-button" style={{ padding: 20 }}>
                <Input
                      id="import-button"
                      inputProps={{
                        accept:
                          ".xlsx",
                      }}
                      type="file"
                      onChange={(e) => props.onChangeUploadFile(e)}
                  />
                  Import Excel
                    </InputLabel>}
            </Grid> : null}

        {props.component ? props.component : null}

        {props.customizeDialog && (props.option === "addItem" || props.option === "edit") ? null : props.agreeButton ? <DialogActions>
          <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
          {props.disagreeTxt}
          </Button>
          <Button disableElevation variant="contained" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleAgree} color="primary" autoFocus>
          {props.agreeTxt}
          </Button>
          
        </DialogActions> : null} 

      </Dialog>
    </div>
  );
}