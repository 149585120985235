import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentStaff } from '../../redux/staff/staff.selector'
import { selectCurrentUser } from '../../redux/user/user.selector'

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme) => ({
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

function Header(props) {
  const { classes, onDrawerToggle } = props;

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0} style={{ backgroundColor: "#e1e1e1" }}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            
            <Hidden lgUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>

            <Grid item xs />
            <Grid item>
             <Typography style={{ fontSize: 14, fontWeight: 500 }} variant="caption">
              {props.currentUser &&  props.currentUser.id ? "(" + props.currentUser.id +")": "(User ID No Found)"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ fontSize: 14, fontWeight: 500, marginRight: 10 }} variant="caption">
              {props.currentUser && props.currentUser.last_name && props.currentUser.first_name ? props.currentUser.last_name + " " + props.currentUser.first_name : "Unnamed"}
              </Typography>
              <IconButton color="inherit" sx={{ p: 0.5 }}>
                <Avatar style={{ opacity: .5 }} alt={props.currentUser ? props.currentUser.email : null} src="/broken-image.jpg"></Avatar>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

const mapStatetoProps = createStructuredSelector({
  currentStaff: selectCurrentStaff,
  currentUser: selectCurrentUser,
})

export default withStyles(styles)(connect(mapStatetoProps)(Header));
