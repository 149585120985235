import CategoryActionTypes from './category.types'

const INITIAL_STATE = {
    hidden: true,
    categoryItems: []
}


const categoryReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case CategoryActionTypes.SET_CATEGORY_ITEM:

            return {
                ...state,
                categoryItems: action.payload
            }
        default:
            return state
    }
}

export default categoryReducer