import { StaffActionTypes } from './staff.types'
import firebase from 'firebase/compat/app';


const INITIAL_STATE = {
    currentStaff: null
}
const staffReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case StaffActionTypes.SET_CURRENT_STAFF:

        if(action.payload.logout){
            firebase.database().ref("pos/" + action.payload.concept_store_id + "/staff").remove()

            return {
                ...state,
                currentStaff: null
            }
        }
        else
        {
            firebase.database().ref("pos/" + action.payload.concept_store_id + "/staff").update({ 
                user:  action.payload,
                updated: firebase.database.ServerValue.TIMESTAMP });
        }
            

        return {
            ...state,
            currentStaff: action.payload
        }

        default:
            return state
    }
}

export default staffReducer