import React, { useEffect, useRef }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/compat/app';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Receipt from './Receipt'


const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  },
}));



function Index (props){

  const classes = useStyles();
  const [ordersDetails, setOrdersDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const componentRef = useRef();

  useEffect(() => {

    if(props.match.params.id){

      var _id = props.match.params.id.split("&")[0];
      var _imei = props.match.params.id.split("&")[1];

      let ordersRef = firebase.database().ref("ew_claimed/"+_imei+"/" +_id);

      ordersRef.on('value', snapshot => {
        
        if(snapshot.exists()){
          setOrdersDetails(snapshot.val())
        }
        else{
          setOrdersDetails([])
        }
  
        setLoading(false)
      })
    }
    
    
  }, [props]);

  if(loading){
    return (
    <Grid className={classes.root}>
      <Grid style={{ marginTop: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>
    </Grid>);
  }


  return (
    <Grid className={classes.root}>
      <Grid style={{ padding: 20  }}>
          <Receipt ordersDetails={ordersDetails}/>
      </Grid>
    </Grid>
    
  );
  
}

export default Index;