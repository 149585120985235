import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '../../../modules/components/Dialog';
import Typography from '@material-ui/core/Typography';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import firebase from 'firebase/compat/app';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #6e6e6e',
    width: 70,
    height: 70,
    padding: 4,
    boxSizing: 'border-box',
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8 
};

 
const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

export default function Index(props) {

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");
    const [emailDes, setEmailDes] = React.useState("");
    const [status, setStatus] = React.useState("submit_for_approval");
    const [lastStatus, setLastStatus] = React.useState("");

    const [values, setValues] = React.useState({});

    useEffect(() => {
        setValues(props.addCellsName ? props.addCellsName : {})
        
        if(props.option === "edit"){
            setValues(props.selectedItem ? props.selectedItem : {})
            setStatus(props.selectedItem.status ? props.selectedItem.status : "submit_for_approval")
            setLastStatus(props.selectedItem.status)
        }

        /*let itemRef = firebase.database().ref("ew_claimed");

        itemRef.once('value', snapshot => {
    
            if(snapshot.exists()){

                let items = [];
                
                snapshot.forEach(child => {
                    var itemsVal = child.val();

                    items.push(Object.values(itemsVal)[0]);
                })

                var count = 0;
                items.sort(function custom_sort(a, b) {
                    return a.created - b.created;
                })

                items.forEach(val =>{
                    count++;
                    firebase.database().ref("ew_claimed/" + val.imei_no + "/" +val.id).update({ uuid: count, claim_id: "IP-EW-" + parseInt(count).toLocaleString('en-US', {minimumIntegerDigits: 6, useGrouping:false}) });
                })
            }

        })*/

    }, [props]);

    const handleClose = () => {
        setOpenDialog(false)
    };

    const handleAgree = () => {

        var updates = {};
        if(props.option === "edit"){
            values.id = props.selectedItem.id;
            updates[props.data + '/' + props.selectedItem.imei_no + "/" + props.selectedItem.id] = values;


            /*let itemRef = firebase.database().ref("users");

            itemRef.once('value', snapshot => {
        
                if(snapshot.exists()){

                    let items = [];
                    
                    snapshot.forEach(child => {
                        var itemsVal = child.val();

                        if(itemsVal.email === values.submit_user){
                            items.push(itemsVal);
                        }
                    })

                    firebase.database().ref("ew_claimed/" + values.imei_no + "/" +values.id).update({ submit_user: items[0]}).then(()=>{
                        props.handleClose()
                      })
                }

            })*/
              
            if(lastStatus !== status)
            {
                let emailtxt = "";

                if(status === "submit_for_approval")
                {
                    emailtxt = "Thank you for using the InstaProctection insurance. We are processing your application."
                }
                else if(status === "approved")
                {
                    emailtxt = "Thank you for using the InstaProctection insurance. Your submission has been accepted."
                }
                else if(status === "approved_repair_detail")
                {
                    emailtxt = "Thank you for using the InstaProctection insurance. Your repair detail has been accepted."
                }
                else if(status === "cancelled")
                {
                    emailtxt = "Thank you for using the InstaProctection insurance. Your submission has been cancelled."
                }
                else if(status === "rejected")
                {
                    emailtxt = "Thank you for using the InstaProctection insurance. Your submission has been rejected."
                }
                else if(status === "seek_verification")
                {
                    emailtxt = "Thank you for using the InstaProctection insurance. "
                }

                if(status === "closed")
                {
                    firebase.database().ref().update(updates).then(()=>{ props.handleClose() })
                }
                else
                {
                    var submitted_user_email = "";
                    if(values.submit_user && values.submit_user.email){
                        
                        submitted_user_email = values.submit_user.email;
                    }

                    firebase.database().ref().update(updates).then(()=>{

                        firebase.firestore().collection('mail').add({
                            to: [values.customer_email, submitted_user_email, props.currentUser.email, "lionel.peh@superfunds-venture.com"],
                            
                            message: {
                              subject: 'Insta Protection - Acknowledgement of Update Insurance',
                              html: '<p>Date: ' + new Date().getTime() + '</p>' +
                              '<p>Application reference no:' + values.id  + '</p>' +
                              '<p>IMEI no:' + values.imei_no  + '</p>' +
                              '<p>Dear ' + values.customer_name  + ',</p>' +
                              '<p>'+ emailDes + '</p>' +
                              '<p>'+ emailtxt + '</p>' +
                              '<p>Please do not reply to this email.</p>' +
                              '<p>Your sincerely,</p>' +
                              '<p>Instaprotect Support</p>' + 
                              '<p>[This is a computer-generated email, no signature is required]</p>'
                            },
                          }).then(()=>{
                            props.handleClose()
                          })
    
                    })

                    
                }


                

                
            }

        }
        

        
    }

    const handleEmailDesChange = (e) => {
        setEmailDes(e.target.value)
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChangeStatus = (event) => {
        values.status = event.target.value
        setStatus(event.target.value)
    }

    return (
        <Grid style={{ maxWidth: 625  }}>
            <Dialog 
                agreeTxt={"Yes"}
                disagreeTxt={"No"}
                description={dialogDes}
                title={dialogTitle}
                open={openDialog} 
                agreeButton={agreeButton}
                fullScreenDialog={fullScreenDialog}
                option={option}
                dialogLoading={dialogLoading}
                handleClose={handleClose}/>

            <Grid container style={{ paddingBottom: 10, display: "flex", flexDirection: "row" }}>
                {props[props.option === "edit" ? "editCells" : "addCells"] ? props[props.option === "edit" ? "editCells" : "addCells"].map((cell, index)=>{ 
                const labelCellId = `add-cell-${index}`;
                var sizeMd = 6;

                if(index === props.addCells.length - 1){
                    if((index+1) % 2 !== 0){
                    sizeMd = 12;
                    } 
                }

                if(cell.id === "status"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="Status"
                            onChange={handleChangeStatus}
                            >
                            <MenuItem value={"submit_for_approval"}>Submit for Approval</MenuItem>
                            <MenuItem value={"seek_verification"}>Seek Verification</MenuItem>
                            <MenuItem value={"approved"}>Approved For Repair</MenuItem>
                            <MenuItem value={"approved_repair_detail"}>Approved Repair Detail</MenuItem>
                            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                            <MenuItem value={"rejected"}>Rejected</MenuItem>
                            <MenuItem value={"closed"}>Closed</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>)
                }

                if(cell.id === "email_description"){
                    return(
                        <Grid key={"description"} xs={12} md={12} style={{  padding: 10, display: status === "seek_verification" || 
                        status === "cancelled" ||
                        status === "rejected" ? 
                        "flex" : "none", justifyContent: "center", alignItems: "center"}} item >
                    <TextField
                        key={"textfield-input-description"}
                        label={"Email Description"}
                        style={{ width: "100%"}}
                        multiline
                        value={emailDes}
                        minRows={4}
                        onChange={handleEmailDesChange}
                        variant="outlined"/></Grid>
                    
                    )
                }
                
                
                return(   
                    <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                    <TextField
                        key={"textfield-input-"+index}
                        label={cell.label}
                        
                        style={{ width: "100%"}}
                        value={values[cell.id] || ''}
                        onChange={handleChange(cell.id)}
                        variant="outlined"/></Grid>
                    )}):null }
            </Grid>
            
            <Grid style={{ padding: 10, marginBottom: 30, display: 'flex', justifyContent: "flex-end" }}>
                <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
                {"No"}
                </Button>
                <Button disableElevation variant="contained" style={{ marginLeft: 10, fontSize: 14, fontWeight: 'bold'}} onClick={handleAgree} color="primary" autoFocus>
                {"Yes"}
                </Button>
            </Grid>
        </Grid>
    )

}
  