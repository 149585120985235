import React, { useEffect }  from "react";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from 'firebase/compat/app';
import Backdrop from '@material-ui/core/Backdrop';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DropzonePostRepairImage from '../../modules/components/DropzonePostRepairImage'
import DropzoneRepairInvoice from '../../modules/components/DropzoneRepairInvoice'
import DropzoneServiceRepairReport from '../../modules/components/DropzoneServiceRepairReport'
import InputAdornment from '@material-ui/core/InputAdornment';

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from '../../redux/user/user.selector'

function Index(props) {

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Repair Details', 'Summary', 'Completed'];
    
    const [product, setProduct] = React.useState(null);
    const [ewClaimed, setEwClaimed] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [saveLoading, setSaveLoading] = React.useState(false);

    //step1
    const [repairDate, setRepairDate] = React.useState(null);
    const [repairAmount, setRepairAmount] = React.useState('');
    const [selectedCountry, setSelectedCountry] = React.useState(null);
    const [selectedSC, setSelectedSC] = React.useState(null);
    const [isRepairDate, setIsRepairDate] = React.useState(false);
    const [isRepairAmount, setIsRepairAmount] = React.useState(false);
    const [isSelectedCountry, setIsSelectedCountry] = React.useState(false);
    const [isSelectedSC, setIsSelectedSC] = React.useState(false);
    const [isPostRepairImage, setIsPostRepairImage] = React.useState(false);
    const [isRepairInvoice, setIsRepairInvoice] = React.useState(false);
    const [isServiceRepairReport, setIsServiceRepairReport] = React.useState(false);
    
    const [allCountry, setAllCountry] = React.useState([]);
    const [serviceCenter, setServiceCenter] = React.useState([]);
    const [PostRepairImage, setPostRepairImage] = React.useState([]);
    const [RepairInvoice, setRepairInvoice] = React.useState([]);
    const [ServiceRepairReport, setServiceRepairReport] = React.useState([]);
    const [repairedAmount, setRepairedAmount] = React.useState(0);
    const [EWInfo, setEWInfo] = React.useState([]);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    
    useEffect(() => {

      if(props.product)
      {
        let serviceCentreRef = firebase.database().ref("service_centre");

        serviceCentreRef.on('value', snapshot => {
            let _allServiceCenter = []

            if(snapshot.exists()){
              snapshot.forEach(val => {
                _allServiceCenter.push(val.val())
              })

              const countryList = _allServiceCenter.reduce((items, item) => {
                      
                const {country} = item;
                
                const itemIndex = items.findIndex(item => item.country === country)
                
                if(itemIndex === -1){
                  items.push(item);
                } 
                
                return items;
              }, []);

              setAllCountry(countryList)
              setServiceCenter(_allServiceCenter)
            }

            setProduct(props.product)

            if(props.ewClaimed){
              setEwClaimed(props.ewClaimed)
            }
            
            let ewRef = firebase.database().ref("sc_claimed/" + props.product.imei_no);
  
            ewRef.once('value', snapshot => {
      
              let totalRepairAmount = 0;
              let selectedClaim = []
              if(snapshot.exists()){
                snapshot.forEach(val => {
                  totalRepairAmount += (val.val().repair_amount ? Number(val.val().repair_amount) : 0 )

                  if(!val.val().repair_amount){
                    selectedClaim = val.val();
                  }
                })

                setEWInfo(selectedClaim)
                setRepairedAmount(totalRepairAmount)

                setLoading(false)
              }

            })
        })
      }
      
  
    }, [props]);

    //step 1
    const handleRepairDate = (event) => {
      setRepairDate(event.target.value);
    }

    const handleCountryChange = (event) => {
      setSelectedCountry(event.target.value);
    }
  
    const handleSCChange = (event) => {
      setSelectedSC(event.target.value);
    }

    const handleSubmitStep1 = () => {

      var checkingCount = 0;

      if(repairDate){
        setIsRepairDate(false)
        checkingCount++;
      }
      else
      {
        setIsRepairDate(true)
      }
  
      if(repairAmount && repairAmount > 0){
        setIsRepairAmount(false)
        checkingCount++;
      }
      else
      {
        setIsRepairAmount(true)
      }
  
      if(selectedCountry){
        setIsSelectedCountry(false)
        checkingCount++;
      }
      else
      {
        setIsSelectedCountry(true)
      }
  
      if(selectedSC){
        setIsSelectedSC(false)
        checkingCount++;
      }
      else
      {
        setIsSelectedSC(true)
      }

      if(PostRepairImage.length > 0){
        setIsPostRepairImage(false)
        checkingCount++;
      }
      else
      {
        setIsPostRepairImage(true)
      }
  
      if(RepairInvoice.length > 0){
        setIsRepairInvoice(false)
        checkingCount++;
      }
      else
      {
        setIsRepairInvoice(true)
      }
  
      if(ServiceRepairReport.length > 0){
        setIsServiceRepairReport(false)
        checkingCount++;
      }
      else
      {
        setIsServiceRepairReport(true)
      }

      if(checkingCount === 7){
        setActiveStep(1)
      }
    }

    const handleDropzonePostRepairImage = (files) => {
      setPostRepairImage(files)
    } 
  
    const handleDropzoneRepairInvoice = (files) => {
      setRepairInvoice(files)
    } 
  
    const handleDropzoneServiceRepairReport = (files) => {
      setServiceRepairReport(files)
    } 

    //step 2
    const handleSubmitStep2 = () => {
      setSaveLoading(true)

      var upload_task = firebase.storage().ref("sc_post_repair_image").child(new Date().getTime() +"_"+PostRepairImage[0].name).put(PostRepairImage[0]);
      upload_task.then((snapshot) => {

        firebase.storage().ref("sc_post_repair_image").child(snapshot.metadata.name)
        .getDownloadURL().then((url) => {

          var fileData = {
            name:PostRepairImage[0].name,
            file_name: snapshot.metadata.name,
            url: url,
            size: snapshot.metadata.size,
            created: firebase.database.ServerValue.TIMESTAMP,
            type: snapshot.metadata.contentType,
            full_path: snapshot.metadata.fullPath
          };

          var upload_task_2 = firebase.storage().ref("sc_repair_invoice").child(new Date().getTime() +"_"+RepairInvoice[0].name).put(RepairInvoice[0]);
          upload_task_2.then((snapshot) => {

            firebase.storage().ref("sc_repair_invoice").child(snapshot.metadata.name)
            .getDownloadURL().then((url) => {

              var fileData2 = {
                name:RepairInvoice[0].name,
                file_name: snapshot.metadata.name,
                url: url,
                size: snapshot.metadata.size,
                created: firebase.database.ServerValue.TIMESTAMP,
                type: snapshot.metadata.contentType,
                full_path: snapshot.metadata.fullPath
              };


              var upload_task_3 = firebase.storage().ref("sc_service_repair_report").child(new Date().getTime() +"_"+ServiceRepairReport[0].name).put(ServiceRepairReport[0]);
              upload_task_3.then((snapshot) => {

                firebase.storage().ref("sc_service_repair_report").child(snapshot.metadata.name)
                .getDownloadURL().then((url) => {

                  var fileData3 = {
                    name:ServiceRepairReport[0].name,
                    file_name: snapshot.metadata.name,
                    url: url,
                    size: snapshot.metadata.size,
                    created: firebase.database.ServerValue.TIMESTAMP,
                    type: snapshot.metadata.contentType,
                    full_path: snapshot.metadata.fullPath
                  };

                  let selectedClaim = null;

                  if(ewClaimed)
                  {
                    var _ewClaimed = Object.values(ewClaimed);
                    _ewClaimed.forEach(val =>{
                    
                      if(!val.repair_date)
                      {
                        selectedClaim = val;
                      }
                    })
                  }

                  if(selectedClaim){
                    
                    firebase.database().ref('sc_claimed/' +  product.imei_no + "/" +selectedClaim.id).update({ 
                      repair_date: repairDate,
                      repair_amount: repairAmount,
                      service_center: selectedSC,
                      service_center_name: renderSC(),
                      service_center_country: selectedCountry,
                      post_repair_image: fileData,
                      repair_invoice: fileData2,
                      service_repair_report: fileData3,
                      submit_user_repair_details: props.currentUser,
                      repair_detail_created: firebase.database.ServerValue.TIMESTAMP,
                    }).then(()=>{
                      
                      firebase.firestore().collection('mail').add({
                        to: [EWInfo.customer_email, props.currentUser.email, "lionel.peh@superfunds-venture.com"],
                        //to: ["zhenghao_0723@hotmail.com"],
                        message: {
                          subject: 'Insta Protection - Acknowledgement of Update Insurance',
                          html: '<p>Date: ' + new Date().getTime() + '</p>' +
                          '<p>Application reference no:' + EWInfo.id  + '</p>' +
                          '<p>IMEI no:' + EWInfo.imei_no  + '</p>' +
                          '<p>Dear ' + EWInfo.customer_name  + ',</p>' +
                          '<p>Thank you for using the InstaProctection insurance. </p>' +
                          '<p>Please do not reply to this email.</p>' +
                          '<p>Your sincerely,</p>' +
                          '<p>Instaprotect Support</p>' + 
                          '<p>[This is a computer-generated email, no signature is required]</p>'
                        },
                      }).then(()=>{
                        setSaveLoading(false)
                        setActiveStep(2)
                      })

                    })

                  }
                  

                })

              })

              
              
            })
          

          })

        })

      }).catch((error)=>{
          console.log(error.message)
      })
      
      
    }

    const renderSC = () => {

      var SCname = "";
      var currentIndex = serviceCenter.map(val=> val.id).indexOf(selectedSC);

      if(currentIndex !== -1){
        SCname = serviceCenter[currentIndex].name
      }

      return SCname
    }

    if(loading)
    {
      return (
        <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
          <CircularProgress 
            disableShrink
            color="primary" />
        </div>);
    }

    return(
      <Grid style={{ width: "100%", padding: 20 }}>
        
        <Grid style={{ width: "100%" }}>
          <Stepper activeStep={activeStep} orientation={matches? "horizontal" : "vertical"} style={{ padding: 0}}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Divider style={{  opacity: .2, marginTop: 30 }}/>
        </Grid>

        <Grid container style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: 20 }}>

              <Grid container spacing={2} style={{ display: activeStep === 0 ?  "flex": "none" }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                        id="datetime-local"
                        label="Repair Date / Time *"
                        type="datetime-local"
                        fullWidth
                        value={repairDate}
                        error={isRepairDate}
                        onChange={handleRepairDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                                style={{ width: "100%"}}
                                type="number"
                                error={isRepairAmount}
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        USD
                                      </InputAdornment>
                                    ),
                                }}
                                value={repairAmount}
                                onChange={(e)=> 
                                  {
                                    if(Number(e.target.value) <= (product.repair_cost_limit - repairedAmount).toFixed(2))
                                    {
                                      setRepairAmount(e.target.value)
                                    }
                                    else{
                                      setRepairAmount((product.repair_cost_limit - repairedAmount).toFixed(2))
                                    }
                                  }}/>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
                      <Select
                        labelId="option-simple-select-outlined-label"
                        id="option-simple-select-outlined"
                        value={selectedCountry}
                        error={isSelectedCountry}
                        label={"Country"}
                        onChange={handleCountryChange}
                      >
                        {allCountry.map(val => {
                          return <MenuItem value={val.country.toLowerCase()}>{val.country}</MenuItem>
                        })}
                      </Select>
                    </FormControl>

                    
                  </Grid>

                  {selectedCountry ? <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Service Center</InputLabel>
                      <Select
                        labelId="option-simple-select-outlined-label"
                        id="option-simple-select-outlined"
                        value={selectedSC}
                        error={isSelectedSC}
                        label={"Service Center"}
                        onChange={handleSCChange}
                      >
                        {serviceCenter.map(val => {
                          if(val.country.toLowerCase() === selectedCountry)
                          {
                            return <MenuItem value={val.id}>{val.name}</MenuItem>
                          }
                          else{
                            return null
                          }
                          
                        })}
                      </Select>
                    </FormControl>

                    
                  </Grid> : null}

                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Post Repair Image 
                    </Typography>
                    <DropzonePostRepairImage isUploaded={isPostRepairImage} handleDropzoneInvoice={handleDropzonePostRepairImage}/>
                  </Grid>

                  <Grid  item xs={12} md={6}>
                    <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Repair Invoice
                    </Typography>
                    <DropzoneRepairInvoice isUploaded={isRepairInvoice} handleDropzoneInvoice={handleDropzoneRepairInvoice}/>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Service Repair Report
                    </Typography>
                    <DropzoneServiceRepairReport isUploaded={isServiceRepairReport} handleDropzoneInvoice={handleDropzoneServiceRepairReport}/>
                  </Grid>

                  <Grid item xs={12} md={12} style={{ width: "100%" , display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <Button
                      style={{textTransform: 'none', marginLeft: 10   }}
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={()=> handleSubmitStep1()}
                    >
                  Next
                    </Button>
                  </Grid>

            </Grid>

            <Grid container spacing={2} style={{ display: activeStep === 1 ?  "flex": "none" }}>

              <Grid item xs={12}  style={{ marginBottom: 10 }}>
                <Typography color="primary" variant='body1' style={{ fontWeight: "bold" }}>
                  Product Details
                </Typography>

                <Grid container  style={{ marginTop: 20 }}>
                  <Grid item xs={6}>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      IMEI No.
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      IMEI 2 No.
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Product Brand
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Product Model
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Coverage Start Date
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Coverage End Date
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                     Purchase Date
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>
                      {product.imei_no ? product.imei_no : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.imei_no_2 ? product.imei_no_2 : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.physical_device_brand ? product.physical_device_brand : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.physical_device_model_no ? product.physical_device_model_no : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.sc_service_plan_start_date ? moment(product.sc_service_plan_start_date).format("DD-MM-YYYY") : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.sc_service_plan_expired_date ? moment(product.sc_service_plan_expired_date).format("DD-MM-YYYY") : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.sc_service_plan_purchase_date ? moment(product.sc_service_plan_purchase_date).format("DD-MM-YYYY") : "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography color="primary" variant='body1' style={{ fontWeight: "bold", marginTop: 20 }}>
                  Repair Details
                </Typography>

                <Grid container  style={{ marginTop: 10 }}>
                  <Grid item xs={6}>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Repair Date/Time 
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Repair Amount
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Service Center
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                    Post Repair Image
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                    Repair Invoice
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                    Service Repair Report
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>
                      {repairDate ? moment(repairDate).format("DD-MM-YYYY HH:mm") : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {repairAmount ? "USD " +repairAmount : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {selectedSC ? renderSC() : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {PostRepairImage.length > 0 ? "Yes" : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {RepairInvoice.length > 0 ? "Yes" : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {ServiceRepairReport.length > 0 ? "Yes" : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                

              </Grid>

              <Grid item xs={12} md={12} style={{ width: "100%" , display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    variant="contained"
                    disableElevation
                    onClick={()=> setActiveStep(0)}
                  >
                Back
                  </Button>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={()=> handleSubmitStep2()}
                  >
                Submit
                  </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ display: activeStep === 2 ?  "flex": "none" }}>

              <Grid item xs={12}  style={{ marginBottom: 10, display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Typography align="center" color="primary" variant='body1' style={{ fontWeight: "bold", fontSize: 24, display: "flex", justifyContent: "center" }}>
                  Thank you for your submission!
                </Typography>
                <Grid style={{ height: 150, display: "flex", justifyContent: "center", marginTop: 10  }}>
                <img height="100%" src="assets/success.png" alt="success" />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} style={{ width: "100%" , display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={()=> props.handleClose()}
                  >
                Done
                  </Button>
                </Grid>
            </Grid>

        </Grid>

        <Backdrop style={{ zIndex: 999 }} open={saveLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    )
}
const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default connect(mapStatetoProps)(Index);