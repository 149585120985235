import React from "react";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import app from "../../firebase/base";
import { useHistory } from "react-router-dom"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://instaprotection.sg/">
      Instaprotection Pte. Ltd. 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    display:"flex",
    justifyContent: "center",
    padding: 20
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
});

function SignIn(props) {

  const classes = props.classes;
  let history = useHistory();
  
  return (
    <Grid container component="main" className={classes.root}>
      <Grid item component={Paper} elevation={0} style={{ width: 400, marginTop: 50, backgroundColor: "#f2f2f2" }}>
        <Grid style={{ display: 'flex', justifyContent: "space-between", height: 120, backgroundColor: "#e7e7e7", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
          
          <Grid>
            <Typography color="primary" variant='body1' style={{  marginLeft: 30, marginTop: 40, fontWeight: "bold"}}>
              New User Approval
            </Typography>
            <Typography color="primary" variant='body2' style={{  marginLeft: 30 }}>
              Waiting for approval
            </Typography>
          </Grid>
          
          <img height="100%" src="assets/login/profile-img.png" alt="top-login-bg" />
        </Grid>
        <div className={classes.paper}>
          
        <form className={classes.form}>

              <Grid style={{ display: 'flex', justifyContent: "center", flexDirection: "column" }}>
                <Typography variant='body2' align="center">
                Your account is waiting for
                </Typography>
                <Typography variant='body2' align="center">
                our administrator approval.
                </Typography>
                <Typography variant='body2' align="center">
                Please check back later.
                </Typography>
              </Grid>

              <Button
                style={{textTransform: 'none', width: "100%", marginTop: 30}}
                fullWidth
                color="primary"
                disableElevation
                onClick={()=> app.auth().signOut().then(()=> { history.push("/") })}
              >
              Login with Another Phone Number
              </Button>

              <Grid style={{ display: 'flex', justifyContent: "center", marginTop: 20 }}>
              <img height="80" src="assets/logo/logo-black.png" alt="Insta Protection logo"/>
              </Grid>
              
              
              <Grid style={{ display: 'flex', justifyContent: "center", marginTop: 20 }}>
                <Copyright />
              </Grid>
          </form>
        </div>
      </Grid>
    </Grid>);
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);