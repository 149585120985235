import React, { useEffect }  from "react";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DropzoneInvoice from '../../modules/components/DropzoneInvoice'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';
import DropzonePreRepairImage from '../../modules/components/DropzonePreRepairImage'
import PhoneInput from 'react-phone-input-2'
import firebase from 'firebase/compat/app';
import Backdrop from '@material-ui/core/Backdrop';

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from '../../redux/user/user.selector'

function Index(props) {

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Submission', 'Summary', 'Completed'];
    
    const [product, setProduct] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [saveLoading, setSaveLoading] = React.useState(false);

    //step1
    const [invoice, setInvoice] = React.useState([]);
    const [isInvoice, setIsInvoice] = React.useState(false);

    //step2
    const [incidentDate, setIncidentDate] = React.useState(null);
    const [incidentLocation, setIncidentLocation] = React.useState('');
    const [incidentDetail, setIncidentDetail] = React.useState('');
    const [isIncidentDate, setIsIncidentDate] = React.useState(false);
    const [isIncidentLocation, setIsIncidentLocation] = React.useState(false);
    const [isIncidentDetail, setIsIncidentDetail] = React.useState(false);
    const [isPreRepairImage, setIsPreRepairImage] = React.useState(false);
    const [PreRepairImage, setPreRepairImage] = React.useState([]);

    //step3
    const [customerName, setCustomerName] = React.useState('');
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [customerEmail, setCustomerEmail] = React.useState('');
    const [isCustomerName, setIsCustomerName] = React.useState(false);
    const [isCustomerNumber, setIsCustomerNumber] = React.useState(false);
    const [isCustomerEmail, setIsCustomerEmail] = React.useState(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    
    useEffect(() => {

      if(props.product)
      {
        setProduct(props.product)
        setLoading(false)
      }
      
  
    }, [props]);

    //step1
    const handleDropzoneInvoice = (files) => {
      setInvoice(files)
    } 

    const handleSubmitStep1 = () => {


      var checkingCount = 0;
      if(invoice.length > 0){
        setIsInvoice(false)

        checkingCount++;
      }
      else
      {
        setIsInvoice(true)
      }

      

      if(incidentDate){
        setIsIncidentDate(false)
        checkingCount++;
      }
      else
      {
        setIsIncidentDate(true)
      }

      if(incidentLocation.length > 3){
        setIsIncidentLocation(false)
        checkingCount++;
      }
      else
      {
        setIsIncidentLocation(true)
      }
  
      if(incidentDetail.length > 3){
        setIsIncidentDetail(false)
        checkingCount++;
      }
      else
      {
        setIsIncidentDetail(true)
      }

      if(PreRepairImage.length > 0){
        setIsPreRepairImage(false)
        checkingCount++;
      }
      else
      {
        setIsPreRepairImage(true)
      }

      if(customerName.length > 3){
        setIsCustomerName(false)
        checkingCount++;
      }
      else
      {
        setIsCustomerName(true)
      }
  
      if(customerNumber.length > 7){
        setIsCustomerNumber(false);
        checkingCount++;
      }
      else
      {
        setIsCustomerNumber(true)
      }
  
      if(customerEmail && validateEmail(customerEmail)){
        setIsCustomerEmail(false)
        checkingCount++;
      }
      else
      {
        setIsCustomerEmail(true)
      }

      if(checkingCount === 8){
        setActiveStep(1)
      }
      
    }

    //step2
    const handleIncidentDate = (event) => {
      setIncidentDate(event.target.value);
    }

    const handleIncidentLocation = (event) => {
      setIncidentLocation(event.target.value);
    }
  
    const handleIncidentDetail= (event) => {
      setIncidentDetail(event.target.value);
    }

    const handleDropzonePreRepairImage = (files) => {
      setPreRepairImage(files)
    } 

    const handleSubmitStep2 = () => {

      var checkingCount = 0;

      if(incidentDate){
        setIsIncidentDate(false)
        checkingCount++;
      }
      else
      {
        setIsIncidentDate(true)
      }

      if(incidentLocation.length > 3){
        setIsIncidentLocation(false)
        checkingCount++;
      }
      else
      {
        setIsIncidentLocation(true)
      }
  
      if(incidentDetail.length > 3){
        setIsIncidentDetail(false)
        checkingCount++;
      }
      else
      {
        setIsIncidentDetail(true)
      }

      if(PreRepairImage.length > 0){
        setIsPreRepairImage(false)
        checkingCount++;
      }
      else
      {
        setIsPreRepairImage(true)
      }

      if(checkingCount === 4){
        setActiveStep(2)
      }
      
    }

    //step3
    const handleCustomerName = (event) => {
      setCustomerName(event.target.value);
    }
  
    const handleCustomerNumber = (value) => {
      setCustomerNumber(value);
    }
  
    const handleCustomerEmail = (event) => {
      setCustomerEmail(event.target.value);
    }

    const handleSubmitStep3 = () => {
      var checkingCount = 0;

      if(customerName.length > 3){
        setIsCustomerName(false)
        checkingCount++;
      }
      else
      {
        setIsCustomerName(true)
      }
  
      if(customerNumber.length > 7){
        setIsCustomerNumber(false);
        checkingCount++;
      }
      else
      {
        setIsCustomerNumber(true)
      }
  
      if(customerEmail && validateEmail(customerEmail)){
        setIsCustomerEmail(false)
        checkingCount++;
      }
      else
      {
        setIsCustomerEmail(true)
      }

      if(checkingCount === 3){
        setActiveStep(3)
      }

    }

    const validateEmail = (email) => {
      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regexp.test(email);
    }

    const getID =()=>{
      let ewUUIDRef = firebase.database().ref("sc_uuid")

      ewUUIDRef.once('value', snapshot => {
        if(snapshot.exists()){
          let val = snapshot.val()

          let newUUID = Number(val.uuid)+1
         
          firebase.database().ref("sc_uuid").update({ uuid: newUUID })
          .then(()=>{

            saveData(newUUID)
          })
        }
        else
        {
         
          firebase.database().ref("sc_uuid").update({ uuid: 1 })
          .then(()=>{

            saveData(1)

          })
        }

        
      })
}

    const saveData = (uuid) => {
      var updates = {};
      var values = {};
    
      values.imei_no = product.imei_no ? product.imei_no : null;
      values.imei_no_2 = product.imei_no_2 ? product.imei_no_2 : null;
      values.created = firebase.database.ServerValue.TIMESTAMP;
      values.updated = firebase.database.ServerValue.TIMESTAMP;
      values.physical_device_brand = product.physical_device_brand ? product.physical_device_brand : null;
      values.physical_device_model_no = product.physical_device_model_no ? product.physical_device_model_no : null;
      values.sc_service_plan_start_date = product.sc_service_plan_start_date ? product.sc_service_plan_start_date : null;
      values.sc_service_plan_expired_date = product.sc_service_plan_expired_date ? product.sc_service_plan_expired_date : null;
      values.sc_service_plan_purchase_date = product.sc_service_plan_purchase_date ? product.sc_service_plan_purchase_date : null;

      values.incident_date = incidentDate;
      values.incident_location = incidentLocation;
      values.incident_detail = incidentDetail;

      values.customer_name = customerName;
      values.customer_phone = customerNumber;
      values.customer_email = customerEmail;
      values.status = "submit_for_approval"
      values.submit_user = props.currentUser;

      var upload_task = firebase.storage().ref("sc_purchase_invoice").child(new Date().getTime() +"_"+invoice[0].name).put(invoice[0]);
      upload_task.then((snapshot) => {

        firebase.storage().ref("sc_purchase_invoice").child(snapshot.metadata.name)
        .getDownloadURL().then((url) => {

          var fileData = {
            name:invoice[0].name,
            file_name: snapshot.metadata.name,
            url: url,
            size: snapshot.metadata.size,
            created: firebase.database.ServerValue.TIMESTAMP,
            type: snapshot.metadata.contentType,
            full_path: snapshot.metadata.fullPath
          };

          values.purchase_invoice = fileData;

          var upload_task_2 = firebase.storage().ref("sc_pre_repair_image").child(new Date().getTime() +"_"+PreRepairImage[0].name).put(PreRepairImage[0]);
          upload_task_2.then((snapshot) => {

            firebase.storage().ref("sc_pre_repair_image").child(snapshot.metadata.name)
            .getDownloadURL().then((url) => {

              var newPostKey = firebase.database().ref().child('sc_claimed/' + product.imei_no).push().key;

              var fileData2 = {
                name:invoice[0].name,
                file_name: snapshot.metadata.name,
                url: url,
                size: snapshot.metadata.size,
                created: firebase.database.ServerValue.TIMESTAMP,
                type: snapshot.metadata.contentType,
                full_path: snapshot.metadata.fullPath
              };

              values.pre_repair_image = fileData2;
              values.id = newPostKey;
              values.claim_id = "IP-SC-" + parseInt(uuid).toLocaleString('en-US', {minimumIntegerDigits: 6, useGrouping:false});
              values.uuid = uuid;
              updates['sc_claimed/' + product.imei_no + "/" + newPostKey] = values;

              firebase.database().ref().update(updates).then(()=>{
                

                firebase.firestore().collection('mail').add({
                  to: [values.customer_email, props.currentUser.email, "lionel.peh@superfunds-venture.com"],
                  message: {
                    subject: 'Insta Protection - Acknowledgement of Update Insurance',
                    html: '<p>Date: ' + new Date().getTime() + '</p>' +
                    '<p>Application reference no:' + values.id  + '</p>' +
                    '<p>IMEI no:' + values.imei_no  + '</p>' +
                    '<p>Dear ' + values.customer_name  + ',</p>' +
                    '<p>Thank you for using the InstaProctection insurance. We are processing your application.</p>' +
                    '<p>Please do not reply to this email.</p>' +
                    '<p>Your sincerely,</p>' +
                    '<p>Instaprotect Support</p>' + 
                    '<p>[This is a computer-generated email, no signature is required]</p>'
                  },
                }).then(()=>{
                  setSaveLoading(false)
                  setActiveStep(4)
                })

              })
              
            })
          

          })

        })

      }).catch((error)=>{
          console.log(error.message)
      })
    }

    //step 4
    const handleSubmitStep4 = () => {
      setSaveLoading(true)
      getID();

    }

    if(loading)
    {
      return (
        <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
          <CircularProgress 
            disableShrink
            color="primary" />
        </div>);
    }

    return(
      <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", padding: 20 }}>
        
        <Grid style={{ width: "100%" }}>
          <Stepper activeStep={activeStep} orientation={matches? "horizontal" : "vertical"} style={{ padding: 0}}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Divider style={{  opacity: .2, marginTop: 30 }}/>
        </Grid>

        <Grid container style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: 20, maxWidth: 600 }}>

            <Grid container spacing={2} style={{ display: activeStep === 0 ?  "flex": "none" }}>
                <Grid item xs={12} md={6}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    IMEI No
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={product.imei_no ? product.imei_no : "-"}/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    IMEI 2 No
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={product.imei_no_2 ? product.imei_no_2 : "-"}/>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Product Brand
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={product.physical_device_brand?product.physical_device_brand: "-"}/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Product Model
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={product.physical_device_model_no ? product.physical_device_model_no : "-"}/>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Coverage Start Date
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={moment(product.sc_service_plan_start_date).format("DD-MM-YYYY")}/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                  Coverage End Date
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={moment(product.sc_service_plan_expired_date).format("DD-MM-YYYY")}/>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    style={{ marginTop: 5, marginBottom: 5}}
                      id="datetime-local"
                      label="Purchase Date"
                      type={"date"}
                      disabled
                      fullWidth
                      format="MM/dd/yyyy"
                      value={product.sc_service_plan_purchase_date ? moment(product.sc_service_plan_purchase_date).format("YYYY-MM-DD") : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                  Purchase Invoice
                  </Typography>
                  <DropzoneInvoice isUploaded={isInvoice} handleDropzoneInvoice={handleDropzoneInvoice}/>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: 5, marginBottom: 5}}>
                <Grid item xs={12}>
                  <TextField
                      id="datetime-local"
                      label="Incident Date / Time *"
                      type="datetime-local"
                      fullWidth
                      error={isIncidentDate}
                      value={incidentDate}
                      onChange={handleIncidentDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth variant="outlined" label="Incident Location *" 
                    error={isIncidentLocation}
                    onChange={handleIncidentLocation}
                    value={incidentLocation}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField minRows={3} multiline fullWidth variant="outlined" label="Incident Detail *" 
                    error={isIncidentDetail}
                    onChange={handleIncidentDetail}
                    value={incidentDetail} />

                <Typography variant='body2' style={{ marginBottom: 5, marginTop: 20 }}>
                    Pre Repair Image
                  </Typography>
                  <DropzonePreRepairImage isUploaded={isPreRepairImage} handleDropzoneInvoice={handleDropzonePreRepairImage}/>
                </Grid>

            </Grid>

            <Grid container spacing={2} style={{ marginTop: 5, marginBottom: 5}}>
                  <Grid item xs={12}>
                    <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                      Customer Name *
                    </Typography>
                    <TextField fullWidth variant="outlined" 
                      error={isCustomerName}
                      onChange={handleCustomerName}
                      value={customerName}/>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PhoneInput
                        containerStyle={{ marginTop: 10, marginBottom: 10 }}
                        dropdownStyle={{ color: "#1c1c1c" }}
                        inputStyle={{ width: "100%"}}
                        country={'sg'}
                        value={customerNumber}
                        onChange={handleCustomerNumber}
                      />
                      {isCustomerNumber ? 
                      <Typography color={"error"} variant='caption' >
                      {"Invalid Phone Number"}
                      </Typography> : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField fullWidth variant="outlined" label="Customer Email *"  
                     error={isCustomerEmail}
                      onChange={handleCustomerEmail}
                      value={customerEmail}/>
                  </Grid>

            </Grid>

                <Grid item xs={12} md={12} style={{ width: "100%" , display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={()=> handleSubmitStep1()}
                  >
                Next
                  </Button>
                </Grid>

            </Grid>

            

            <Grid container spacing={2} style={{ display: activeStep === 1 ?  "flex": "none" }}>

              <Grid item xs={12}  style={{ marginBottom: 10 }}>
                <Typography color="primary" variant='body1' style={{ fontWeight: "bold" }}>
                  Product Details
                </Typography>

                <Grid container  style={{ marginTop: 20 }}>
                  <Grid item xs={6}>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      IMEI No.
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      IMEI 2 No.
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Product Brand
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Product Model
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Coverage Start Date
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Coverage End Date
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                     Purchase Date
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                    Purchase Invoice
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>
                      {product.imei_no ? product.imei_no : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.imei_no_2 ? product.imei_no_2 : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.physical_device_brand ? product.physical_device_brand : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.physical_device_model_no ? product.physical_device_model_no : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.sc_service_plan_start_date ? moment(product.sc_service_plan_start_date).format("DD-MM-YYYY") : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.sc_service_plan_expired_date ? moment(product.sc_service_plan_expired_date).format("DD-MM-YYYY") : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.sc_service_plan_purchase_date ? moment(product.sc_service_plan_purchase_date).format("DD-MM-YYYY") : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {invoice.length > 0 ? "Yes" : "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography color="primary" variant='body1' style={{ fontWeight: "bold", marginTop: 20 }}>
                  Incident Details
                </Typography>

                <Grid container  style={{ marginTop: 10 }}>
                  <Grid item xs={6}>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Incident Date/Time 
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Incident Location
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Incident Detail
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                    Pre Repair Image
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>
                      {incidentDate ? moment(incidentDate).format("DD-MM-YYYY HH:mm") : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {incidentLocation ? incidentLocation : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {incidentDetail ? incidentDetail : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {PreRepairImage.length > 0 ? "Yes" : "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography color="primary" variant='body1' style={{ fontWeight: "bold", marginTop: 20 }}>
                  Customer Details
                </Typography>

                <Grid container  style={{ marginTop: 10 }}>
                  <Grid item xs={6}>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Customer Name
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Customer Contact No.
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Customer Email
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>
                      {customerName ? customerName : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {customerNumber ? customerNumber : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {customerEmail ? customerEmail : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                

              </Grid>

              <Grid item xs={12} md={12} style={{ width: "100%" , display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    variant="contained"
                    disableElevation
                    onClick={()=> setActiveStep(0)}
                  >
                Back
                  </Button>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={()=> handleSubmitStep4()}
                  >
                Submit
                  </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ display: activeStep === 4 ?  "flex": "none" }}>

              <Grid item xs={12}  style={{ marginBottom: 10, display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Typography align="center" color="primary" variant='body1' style={{ fontWeight: "bold", fontSize: 24, display: "flex", justifyContent: "center" }}>
                  Thank you for your submission!
                </Typography>
                <Grid style={{ height: 150, display: "flex", justifyContent: "center", marginTop: 10  }}>
                <img height="100%" src="assets/success.png" alt="success" />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} style={{ width: "100%" , display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={()=> props.handleClose()}
                  >
                Done
                  </Button>
                </Grid>
            </Grid>

        </Grid>

        <Backdrop style={{ zIndex: 999 }} open={saveLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    )
}
  
const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default connect(mapStatetoProps)(Index);