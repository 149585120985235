import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddEditEW from './AddEditEW';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/compat/app';

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from '../../../redux/user/user.selector'

function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const [scName, setScName] = React.useState("");
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'id', label: 'ID' },
            { id: 'last_name', label: 'Last Name' },
            { id: 'first_name', label: 'First Name' },
            { id: 'created', label: 'Created' },
            { id: 'phone', label: 'Phone' },
            { id: 'role', label: 'Role' },
            { id: 'status_users', label: 'Status' },
        ])

        setHeadMobileCells([
            { id: 'id', label: 'ID' },
            { id: 'phone', label: 'Phone' },
        ])
    
        setSearchCell([
            { id: 'id', label: 'ID' },
            { id: 'email', label: 'Email' },
            { id: 'phone', label: 'Phone' },
        ])

        setDisplayInfo([
            { id: 'id', label: 'ID' },
            { id: 'last_name', label: 'Last Name' },
            { id: 'first_name', label: 'First Name' },
            { id: 'created', label: 'Created' },
            { id: 'email', label: 'Email' },
            { id: 'phone', label: 'Phone' },
            { id: 'role', label: 'Role' },
            { id: 'status_users', label: 'Status' },
        ])

        setEditCells([
            { id: 'last_name', label: 'Last Name' },
            { id: 'first_name', label: 'First Name' },
            { id: 'email', label: 'Email' },
            { id: 'phone', label: 'Phone' },
        ])

        setAddCells([
            { id: 'last_name', label: 'Last Name' },
            { id: 'first_name', label: 'First Name' },
            { id: 'email', label: 'Email' },
            { id: 'phone', label: 'Phone' },
           
        ])

        setAddCellsName({ 
            last_name: '', 
            first_name: '', 
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            email: '',
            role: "staff",
            status_users: "active",
            service_center_id: props.currentUser.service_center_id
         })
    
        setSearchValue("id")

        let SCRef = firebase.database().ref("service_centre");

        SCRef.on('value', snapshot => {
            if(snapshot.exists()){
                let _scName = "";
  
                snapshot.forEach(child => {
                    var itemsVal = child.val();
                    if(itemsVal.id === props.currentUser.service_center_id)
                    {
                        _scName = itemsVal.name
                    }
                })

                setScName(_scName)
            }
        })
        
    }, []);

    return (
    <Grid>
        <Typography variant='body2' style={{ fontSize: 24, fontWeight: "bold", padding: 20}}>
        {scName}
        </Typography>
        <Table 
            control={true}
            hideDelete={true}
            disabledMove={true}
            hideAllDelete={firebase.auth().currentUser.displayName === "admin" ? false : true}
            addItemButton={firebase.auth().currentUser.displayName === "admin" ? true : false}
            editButton={firebase.auth().currentUser.displayName === "admin" ? true : false}
            editCells={editCells}
            addItemText={"Add Staff"}
            customizeDialog={AddEditEW}
            addCells={addCells}
            addCellsName={addCellsName}
            orderBy="created" 
            data={"users"} 
            displayInfo={displayInfo}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}

const mapStatetoProps = createStructuredSelector({
    currentUser: selectCurrentUser,
})
  
export default connect(mapStatetoProps)(Index);
  