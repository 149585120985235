import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddEditEWList from './AddEditEWList';
import firebase from 'firebase/compat/app';

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const [excelList, setExcelList] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'imei_no', label: 'Imei No' },
            { id: 'plan', label: 'Plan' },
            { id: 'batch', label: 'Batch' },
            { id: 'capacity', label: 'Capacity' },
        ])

        setHeadMobileCells([
            { id: 'imei_no', label: 'Imei No' },
        ])
    
        setSearchCell([
            { id: 'imei_no', label: 'Imei No' },
        ])

        setDisplayInfo([
            { id: 'imei_no', label: 'Imei No' },
            { id: 'batch', label: 'Batch' },
            { id: 'capacity', label: 'Capacity' },
            { id: 'ew_service_plan_expired_date', label: 'Service Plan Expired Date' },
            { id: 'ew_service_plan_start_date', label: 'Service Plan Start Date' },
            { id: 'extended_warranty', label: 'Extended Warranty' },
            { id: 'physical_device_brand', label: 'Physical Device Brand' },
            { id: 'physical_device_model_no', label: 'Physical Device Model No' },
            { id: 'physical_device_serial_no', label: 'Physical Device Serial No' },
            { id: 'physical_device_srp_price', label: 'Physical Device Srp Price' },
            { id: 'plan', label: 'Plan' },
            { id: 'premium_amount', label: 'Premium Amount' },
            { id: 'repair_cost_limit', label: 'Repair Cost Limit' },
            { id: 'service_plan_purchase_date', label: 'Service Plan Purchase Date' },
        ])

        setEditCells([
            { id: 'imei_no', label: 'Imei No' },
            { id: 'batch', label: 'Batch' },
            { id: 'capacity', label: 'Capacity' },
            { id: 'ew_service_plan_expired_date', label: 'Service Plan Expired Date' },
            { id: 'ew_service_plan_start_date', label: 'Service Plan Start Date' },
            { id: 'extended_warranty', label: 'Extended Warranty' },
            { id: 'physical_device_brand', label: 'Physical Device Brand' },
            { id: 'physical_device_model_no', label: 'Physical Device Model No' },
            { id: 'physical_device_serial_no', label: 'Physical Device Serial No' },
            { id: 'physical_device_srp_price', label: 'Physical Device Srp Price' },
            { id: 'plan', label: 'Plan' },
            { id: 'premium_amount', label: 'Premium Amount' },
            { id: 'repair_cost_limit', label: 'Repair Cost Limit' },
            { id: 'service_plan_purchase_date', label: 'Service Plan Purchase Date' },
        ])

        setAddCells([
            { id: 'imei_no', label: 'Imei No' },
            { id: 'batch', label: 'Batch' },
            { id: 'capacity', label: 'Capacity' },
            { id: 'ew_service_plan_expired_date', label: 'Service Plan Expired Date' },
            { id: 'ew_service_plan_start_date', label: 'Service Plan Start Date' },
            { id: 'extended_warranty', label: 'Extended Warranty' },
            { id: 'physical_device_brand', label: 'Physical Device Brand' },
            { id: 'physical_device_model_no', label: 'Physical Device Model No' },
            { id: 'physical_device_serial_no', label: 'Physical Device Serial No' },
            { id: 'physical_device_srp_price', label: 'Physical Device Srp Price' },
            { id: 'plan', label: 'Plan' },
            { id: 'premium_amount', label: 'Premium Amount' },
            { id: 'repair_cost_limit', label: 'Repair Cost Limit' },
            { id: 'service_plan_purchase_date', label: 'Service Plan Purchase Date' },
        ])

        setExcelList([
            { id: 'imei_no', label: 'Imei No' },
            { id: 'batch', label: 'Batch' },
            { id: 'capacity', label: 'Capacity' },
            { id: 'ew_service_plan_expired_date', label: 'Service Plan Expired Date' },
            { id: 'ew_service_plan_start_date', label: 'Service Plan Start Date' },
            { id: 'extended_warranty', label: 'Extended Warranty' },
            { id: 'physical_device_brand', label: 'Physical Device Brand' },
            { id: 'physical_device_model_no', label: 'Physical Device Model No' },
            { id: 'physical_device_serial_no', label: 'Physical Device Serial No' },
            { id: 'physical_device_srp_price', label: 'Physical Device Srp Price' },
            { id: 'plan', label: 'Plan' },
            { id: 'premium_amount', label: 'Premium Amount' },
            { id: 'repair_cost_limit', label: 'Repair Cost Limit' },
            { id: 'service_plan_purchase_date', label: 'Service Plan Purchase Date' },
            { id: 'fully_submitted', label: 'Fully Submitted' },
            { id: 'repair_amount', label: 'Repair Amount' },
            { id: 'repair_date', label: 'Repair Date' },
            { id: 'incident_detail', label: 'Incident Details' },
            { id: 'status', label: 'Status' },

        ])

        setAddCellsName({ 
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
         })
    
        setSearchValue("imei_no")
        
    }, []);

    return (
    <Grid>
        <Table 
            control={true}
            disabledMove={true}
            customizeDialog={AddEditEWList}
            orderBy="created" 
            displayAllEW={true}
            data={"imei"} 
            displayInfo={displayInfo}
            addCells={addCells}
            addCellsName={addCellsName}
            editCells={editCells}
            matches={matches}
            excelList={excelList}
            hideDelete={firebase.auth().currentUser.displayName === "admin" ? false : true}
            downloadExcel={firebase.auth().currentUser.displayName === "admin" ? true : false}
            hideAllDelete={firebase.auth().currentUser.displayName === "admin" ? false : true}
            addItemButton={firebase.auth().currentUser.displayName === "admin" ? true : false}
            editButton={firebase.auth().currentUser.displayName === "admin" ? true : false}
            uploadExcel={firebase.auth().currentUser.displayName === "admin" ? true : false}
            downloadOfflineExcel={true}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
  