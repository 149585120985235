import firebase from 'firebase/compat/app';

//InstaProtection Claim
const app = firebase.initializeApp({
    apiKey: "AIzaSyDICx5lJb0H0GxE3LZ9qVqXV5fd-lRuBys",
    authDomain: "instaprotection-claim-314ca.firebaseapp.com",
    databaseURL: "https://instaprotection-claim-314ca-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "instaprotection-claim-314ca",
    storageBucket: "instaprotection-claim-314ca.appspot.com",
    messagingSenderId: "47756243426",
    appId: "1:47756243426:web:341021892fa098d07bc9bb"
});

export default app;