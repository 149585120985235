import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/Folder';
import InputIcon from '@material-ui/icons/Input';
import DeleteIcon from '@material-ui/icons/Delete';
import PreviewIcon from '@material-ui/icons/Visibility';
import Receipt from '@material-ui/icons/Receipt';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ReceiptIcon from '@material-ui/icons/Receipt';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import GetAppIcon from '@material-ui/icons/GetApp';

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentStaff } from '../../redux/staff/staff.selector'

function ControlButton(props){

    return(
      <Grid>
      {<Grid>

        {props.orderReceiptButton ? <Tooltip title="Receipt">
          <IconButton component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickReceipt(props.row)}>
              <ReceiptIcon fontSize="small" />
          </IconButton>
        </Tooltip>: null}

        {props.warrantyButton ? <Tooltip title="Warranty">
          <IconButton component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickWarranty(props.row)}>
              <VerifiedUserIcon fontSize="small" />
          </IconButton>
        </Tooltip>: null}

        {props.incidentReport ? <Tooltip title="Incident Report">
        <IconButton component="div" color="primary" onClick={() => props.onClickIncidentReport(props.row)}>
              <ReceiptIcon fontSize="small" />
          </IconButton>
        </Tooltip>: null}

        {props.downloadIncidentReport ? <Tooltip title="Donwload Incident Report">
          <IconButton component="div" color="primary" onClick={() => props.onClickDownloadIR(props.row)}>
              <GetAppIcon fontSize="small" />
          </IconButton>
        </Tooltip> : null }

        {props.orderCheckOutReceiptButton ? <Tooltip title="Receipt">
          <IconButton component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickCheckOutReceipt(props.row)}>
              <ReceiptIcon fontSize="small" />
          </IconButton>
        </Tooltip>: null}

        {props.previewButton ? <Tooltip title="Preview">
          <IconButton component="div" color="primary" onClick={() => props.onClickPreview(props.row)}>
              <PreviewIcon fontSize="small" />
          </IconButton>
        </Tooltip> : null }
        {props.type === "folder" ? <Tooltip title="Enter Folder">
          <IconButton component="div" color="primary" onClick={() => props.onClickOpenFolder(props.row)}>
              <FolderIcon fontSize="small" />
          </IconButton>
        </Tooltip> : null }
        
        <Tooltip title="Info">
          <IconButton component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickInfo(props.row)}>
              <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {props.type === "folder" || props.hideMove ? null : <Tooltip title="Move">
          <IconButton component="div" style={{ display: props.disabledMove? "none": "normal", opacity: .5 }} color="inherit" onClick={() => props.onClickMove(props.row)}>
              <InputIcon fontSize="small" />
          </IconButton>
        </Tooltip>}
        {props.editButton ?<Tooltip title="Edit">
          <IconButton disabled={props.disabledEdit} component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickEdit(props.row)}>
              <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip> : null}
        {props.hideDelete ? null : <Tooltip title="Delete">
            <IconButton disabled={props.disabledDelete ? true : false} component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickDelete(props.row)}>
                <DeleteIcon fontSize="small" />
            </IconButton>
        </Tooltip>}
      </Grid>}
      </Grid>
    )
}

const mapStatetoProps = createStructuredSelector({
  currentStaff: selectCurrentStaff,
})

export default connect(mapStatetoProps)(ControlButton);