import ProductActionTypes from './product.types'

const INITIAL_STATE = {
    hidden: true,
    productItems: []
}


const productReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case ProductActionTypes.SET_PRODUCT_ITEM:

            return {
                ...state,
                productItems: action.payload
            }
        default:
            return state
    }
}

export default productReducer