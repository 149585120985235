import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Error from "./views/pages/Error";
import withRoot from "./modules/withRoot";
import firebase from 'firebase/compat/app';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Grid from '@material-ui/core/Grid';

//Admin
import Navigation from "./views/navigation/Navigation";

import SearchIMEI from "./views/pages/SearchIMEI";
import RegisterUser from "./views/pages/RegisterUser";
import ApprovalUser from "./views/pages/ApprovalUser";
import ClientLogin from "./views/authentication/ClientLogin";

import EWManager from './views/pages/EWManager/EW';
import AllEW from './views/pages/AllEW/EW';
import AllSC from './views/pages/AllSC/ScreenCrack';
import SCManager from './views/pages/SCManager/ScreenCrack';
import CreateStaff from './views/pages/CreateStaff/Staff';
import CreateAllStaff from './views/pages/CreateAllStaff/Staff';
import EWList from './views/pages/EWList';
import SCList from './views/pages/SCList';
import IncidentReport from "./views/pages/AllEW/IncidentReport";
import IncidentSCReport from "./views/pages/AllSC/IncidentReport";

import { connect } from 'react-redux'
import { useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from './redux/user/user.selector'
import { setCurrentUser } from './redux/user/user.actions';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

function App(props) {

  const [authenticated, setAuthenticated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
   
    firebase.auth().onAuthStateChanged(user => {

      

      if (user) 
      {

        let userSessionTimeout = null;

      if (user === null && userSessionTimeout) {
        clearTimeout(userSessionTimeout);
        userSessionTimeout = null;
      } else {
        user.getIdTokenResult().then((idTokenResult) => {
          const authTime = idTokenResult.claims.auth_time * 1000;
          const sessionDurationInMilliseconds = 60 * 60 * 1000; // 60 min
          const expirationInMilliseconds = sessionDurationInMilliseconds - (Date.now() - authTime);
          userSessionTimeout = setTimeout(() => firebase.auth().signOut(), expirationInMilliseconds);
        });
      }

        let userAllRef = firebase.database().ref("users");

        userAllRef.once('value', snapshot => {

          if(snapshot.exists()){

            let foundPhone = null;

            snapshot.forEach(child=>{
              if(child.val().phone === user.phoneNumber){
                foundPhone = child.val().id;
              }
            })

           // foundPhone = "IP000035";
            
            if(foundPhone){
          
              let userRef = firebase.database().ref("users/"+ foundPhone);

              userRef.on('value', snapshot => {
                
                if(snapshot.exists()){

                  const appRef = firebase.auth().currentUser;

                  appRef.updateProfile({
                    uid: snapshot.val().id,
                    displayName: snapshot.val().role
                  })

                  dispatch(setCurrentUser(snapshot.val()))
                  
                  setAuthenticated(true)
                  setLoading(false)

                }
                else{

                  dispatch(setCurrentUser(null))
                  setAuthenticated(true)
                  setLoading(false)
                }

              })

            }
            else
            {
              dispatch(setCurrentUser(null))
              setAuthenticated(true)
              setLoading(false)
            }

          }
          else
          {
            dispatch(setCurrentUser(null))
            setAuthenticated(true)
            setLoading(false)
          }
        })
      }
      else
      {
          dispatch(setCurrentUser(null))
          setAuthenticated(false)
          setLoading(false)
      }

    })

    
  }, [dispatch]);

  const renderManagerRoute = () => {
    return(
      <Switch>
        <Route exact path="/" component={SearchIMEI} />
        <Route exact path="/ew" component={EWManager} />
        <Route exact path="/register_staff" component={CreateStaff} />
        <Route exact path="/screen_crack" component={SCManager} />
        <Route path="*" component={Error} />
      </Switch>
    )
  }

  const renderAdminRoute = () => {
    return(
      <Switch>
        <Route exact path="/" component={SearchIMEI} />
        <Route exact path="/ewlist" component={EWList} />
        <Route exact path="/sclist" component={SCList} />
        <Route exact path="/all_ew" component={AllEW} />
        <Route exact path="/all_sc" component={AllSC} />
        <Route path="/incident_report/:id" component={IncidentReport} />
        <Route path="/incident_sc_report/:id" component={IncidentSCReport} />
        <Route exact path="/all_staff" component={CreateAllStaff} />
        <Route path="*" component={Error} />
      </Switch>
    )
  }

  const renderStaffRoute = () => {
    return(
      <Switch>
        <Route exact path="/" component={SearchIMEI} />
        <Route path="*" component={Error} />
      </Switch>
    )
  }

  const renderNav = () => {
   if(props.currentUser.role === "manager")
   {
    return renderManagerRoute()
   }
   else if(props.currentUser.role === "staff")
   {
    return renderStaffRoute()
   }
   else if(props.currentUser.role === "admin")
   {
    return renderAdminRoute()
   }
   else if(props.currentUser.role === "admin2")
   {
    return renderAdminRoute()
   }
   else
   {
    return renderStaffRoute()
   }
  }

  const renderUserApprovel = () =>{
    if(props.currentUser.status_users === "active"){

      return(
        <Navigation component={renderNav()} />
      )
      
    }
    else{
      return(
        <Grid style={{ display: "flex", justifyContent: "center" }}>
          <ApprovalUser currentUser={props.currentUser}/>
        </Grid>
      )
    }
  }

  if(loading){
    return (
    <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
      <CircularProgress 
        disableShrink
        color="primary" />
    </div>);
  }

  if(authenticated){
    return (
      <BrowserRouter>
          {props.currentUser ? renderUserApprovel() : <RegisterUser/> }
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
        <Switch>
          <Route exact path="/" component={ClientLogin} />
          <Route path="*" component={Error} />
        </Switch>
    </BrowserRouter>
  );
}

const mapDispatchToProps = dispatch => ({
  setCurrentUser: item => dispatch(setCurrentUser(item)),
});

const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default withRoot(connect(mapStatetoProps, mapDispatchToProps)(App));
