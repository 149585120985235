import CartActionTypes from './cart.types'
import { addItemToCart, removeItemFromCart, changeItemQuatityToCart } from './cart.utils'
import firebase from 'firebase/compat/app';


const INITIAL_STATE = {
    hidden: true,
    cartItems: []
}


const cartReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case CartActionTypes.TOGGLE_CART_HIDDEN:
            return {
                ...state,
                hidden: !state.hidden
            }
        case CartActionTypes.ADD_ITEM:

            var _addItemToCard = addItemToCart(state.cartItems, action.payload);
            
            if(action.user){
                firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/carts/"+ action.user.id).update({ 
                    updated: firebase.database.ServerValue.TIMESTAMP, items: _addItemToCard });
            }
            
            
            return {
                ...state,
                cartItems: _addItemToCard
            }

        case CartActionTypes.CHANGE_ITEM_QUANTITY:
            
            var _changeItemQuantityToCard = changeItemQuatityToCart(state.cartItems, action.payload, action.quatity);
            
            if(action.user){
                firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/carts/"+ action.user.id).update({ 
                    updated: firebase.database.ServerValue.TIMESTAMP, items: _changeItemQuantityToCard });
            }
           
            
            return {
                ...state,
                cartItems: _changeItemQuantityToCard
            }

        case CartActionTypes.CLEAR_ITEM_FROM_CART:

            var _clearItemToCard = state.cartItems.filter(cartItem => cartItem.id !== action.payload.id);

            if(action.user){
                firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/carts/"+ action.user.id).update({ 
                    updated: firebase.database.ServerValue.TIMESTAMP, items: _clearItemToCard });
            }
            

            return {
                ...state,
                cartItems: _clearItemToCard
            }

        case CartActionTypes.REMOVE_ITEM:

            var _removeItemToCard = removeItemFromCart(state.cartItems, action.payload);

            if(action.user){
                firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/carts/"+ action.user.id).update({ 
                    updated: firebase.database.ServerValue.TIMESTAMP, items: _removeItemToCard });
            }
            

            return {
                ...state,
                cartItems: _removeItemToCard
            }
        case CartActionTypes.SET_ITEM:

            if(action.user){
                firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/carts/"+ action.user.id).update({ 
                    updated: firebase.database.ServerValue.TIMESTAMP, items: action.payload });
            }
            

            return {
                ...state,
                cartItems: action.payload
            }
        default:
            return state
    }
}

export default cartReducer