import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import {
  TablePagination
} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from './Dialog'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ControlButton from './ControlButton';
import firebase from 'firebase/compat/app';
import Button from '@material-ui/core/Button';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Description from '@material-ui/icons/Description';
import TextField from '@material-ui/core/TextField';
import moment from "moment";

import {useHistory} from "react-router-dom";

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from '../../redux/user/user.selector'
import { selectCurrentStaff } from '../../redux/staff/staff.selector'
import {
  setCurrentUser
} from '../../redux/user/user.actions';

import { useDispatch } from 'react-redux';
import {
  setItem
} from '../../redux/cart/cart.actions';


import {
  setProductItem
} from '../../redux/product/product.actions';

import {
  setCategoryItem
} from '../../redux/category/category.actions';

import { selectProductItems } from '../../redux/product/product.selectors';
import { selectCategoryItems } from '../../redux/category/category.selectors';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import ReactExport from 'react-data-export';
import readXlsxFile from 'read-excel-file'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

//bytesToSize
const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

// Table Sort
function descendingComparator(a, b, orderBy) {

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
  
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

//Table
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    table: {
      minWidth: "100%",
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    datepicker:{
      color: "black",
      background: "white"
    }
}));

function CustomPaginationActionsTable(props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [addItem, setAddItem] = React.useState('');
  const [order, setOrder] = React.useState(props.order ? props.order : 'desc');
  const [orderBy, setOrderBy] = React.useState(props.orderBy);
  const [rowsOriginal, setRowsOriginal] = React.useState([]);
  const [headCells, setHeadCells] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [searchtxt, setSearchtxt] = React.useState("");
  const [searchCell, setSearchCell] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogDes, setDialogDes] = React.useState("");
  const [agreeButton, setAgreeButton] = React.useState(false);
  const [collapse, setCollapse] = React.useState(null);
  const [option, setOptions] = React.useState("");
  const [folderData, setFolderData] = React.useState(null);
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [multiDataSet, setMultiDataSet] = React.useState([]);
  const [offlineList, setOfflineList] = React.useState(false);
  const excelInput = React.useRef(null);
  const [serviceCentre, setServiceCentre] = React.useState([]);

  const [fileUpload, setFileUploaded] = React.useState(null);
  const history = useHistory();

  const [allUser, setAllUser] = React.useState([]);
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));
 
  useEffect(() => {

      let itemRef = firebase.database().ref(props.data);

      itemRef.on('value', snapshot => {
       
        if(snapshot.exists()){
  
          let items = [];
  
          snapshot.forEach(child => {

            
            var itemsVal = child.val();
            
            props.headCells.forEach(headChild =>{
              itemsVal[headChild.id] = itemsVal[headChild.id] ? itemsVal[headChild.id] : ''
            })
            
            if(props.data === "ew_claimed" || props.data === "sc_claimed" ){

              

              
              var _itemsVal = Object.values(itemsVal);
            
              var _itemsVal2 = Object.fromEntries(
                Object.entries(_itemsVal)
                  .filter(item => item[1] !== "")
              );

              var _itemsVal3 = Object.values(_itemsVal2)
              
              _itemsVal3.forEach(val =>{

                
                if(props.displayAllEW){
                 
                  val.fully_submitted = val.repair_date ? "Yes" : "No";
                  val.claim_quantity = val.repair_date ? "1" : "0";
                  items.push(val)
                }
                else{
                  if((val.service_center && val.service_center === props.currentUser.service_center_id) || val.submit_user.service_center_id === props.currentUser.service_center_id)
                  {
                    items.push(val)
                  }
                }
               
               
              })
            }
            else if(props.data === "users"){

              let serviceAllRef = firebase.database().ref("service_centre");

              serviceAllRef.once('value', snapshot => {

                  var _serviceCentre = [];

                  if(snapshot.exists()){

                      snapshot.forEach(child=>{
                          var item = child.val();
                          _serviceCentre.push(item);
                      })
                      setServiceCentre(_serviceCentre);
                  }
              })

              if(itemsVal.service_center_id && itemsVal.service_center_id === props.currentUser.service_center_id && itemsVal.role === "staff")
              {
                items.push(itemsVal)
              }
              else if(props.currentUser && (props.currentUser.role === "admin" || props.currentUser.role === "admin2")){

                
                if(itemsVal.role === "manager" || itemsVal.role === "staff")
                {
                  items.push(itemsVal)
                }
                
              }
            }
            else
            {
              items.push(itemsVal);
            }
            
          })


          if(props.data === "ew_claimed" || props.data === "sc_claimed" ){

            let userAllRef = firebase.database().ref("users");

              userAllRef.once('value', snapshot => {

                  var _users = [];

                  if(snapshot.exists()){

                      snapshot.forEach(child=>{
                          var item = child.val();
                          _users.push(item);
                      })
                      setAllUser(_users);

                      setRowsOriginal(items)
                      setLoading(false)
                  }
              })
          }
          else{
            setRowsOriginal(items)
            setLoading(false)
          }
          
          
          
  
        }
        else
        {
          setRowsOriginal([])
          setLoading(false)
        }
  
        setSelected([])

      });

      setHeadCells(props.headCells ? props.headCells : [])
      setSearchCell(props.searchCell ? props.searchCell : [])
      setSearchValue(props.searchValue ? props.searchValue : "")
      setAddItem(props.addCellsName ? props.addCellsName : [])

      return () => {
        itemRef.off()
      };

  }, [props]);

  const readrowsExcel = () => {

    return readRows();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    if(props.mediaOption && (props.mediaOption === "image" || props.mediaOption === "customer" || props.mediaOption === "invoice")){
      
    }
    else
    {
      if (event.target.checked) {
        const newSelecteds = readRows().map((n) => n);
        setSelected(newSelecteds);
  
        if(props.handleSelectedSation){
          props.handleSelectedSation(newSelecteds)
        }
        
        return;
      }else
      {
        if(props.handleSelectedSation){
          props.handleSelectedSation([])
        }
      }
    }
    
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleCheckBoxClick = (event, name) => {

    if(props.mediaOption && (props.mediaOption === "image" || props.mediaOption === "customer" || props.mediaOption === "invoice")){

      const selectedIndex = selected.indexOf(name);

      if (selectedIndex === -1) {
        setSelected([name]);
      }
      else{
        setSelected([])
      }
      
    }
    else 
    {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      
      if(props.handleSelectedSation){
        props.handleSelectedSation(newSelected)
      }
      
      setSelected(newSelected);
    }
    
  };

  //Search
  const onChangeSearchSelect = event => {
    setSearchtxt("")
    setSearchValue(event.target.value)
  }

  const search = event => {
    setSearchtxt(event.target.value)
    setPage(0)
  }

  //Dialog
  const onClickDeleteAll = () => {
    setDialogLoading(false)
    setAgreeButton(true)
    setDialogTitle("Delete all selected items")
    setOptions("deleteAll")
    setDialogDes("Are you sure you want to delete these items?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const handleSearchWarranty = () => {
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Search Warranty")
    setOptions("searchWarranty")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const onClickUploadExcel = () => {
    setDialogLoading(false);
    setAgreeButton(true);
    setDialogTitle("Upload Excel");
    setOptions("uploadExcel");
    setDialogDes("");
    setFullScreenDialog(false);
    setOpenDialog(true);
  };


  const handleSearchCustomer = () => {
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Search Customer")
    setOptions("searchCustomer")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const onClickMoveAll = () => {
    setDialogLoading(true)
    let itemRef = firebase.database().ref(props.folder_data);
    
    itemRef.once('value', snapshot => {
        
      if(snapshot.exists()){
        let items = [];
        snapshot.forEach(child => {
            
          var itemsVal = child.val();
          items.push(itemsVal);
          
        })
        
        setFolderData(items)
        setDialogLoading(false)
      }
      else
      {
        setFolderData([])
        setDialogLoading(false)
      }

    })

    setAgreeButton(false)
    setDialogTitle("Move all selected items")
    setOptions("moveAll")
    setDialogDes("Are you sure you want to move these items?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickDelete = (row) => {
    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(true)
    setDialogTitle("Delete")
    setOptions("delete")
    setDialogDes("Are you sure you want to delete this item?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickInfo = (row) => {
    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(false)
    setDialogTitle("Info")
    setOptions("info")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickReceipt = (row) => {
    props.history.push('/receipt/' + row.id)
  }

  const onClickWarranty = (row) => {
    props.history.push('/product_warranty/' + row.id)
  }

  const onClickIncidentReport = (row) => {
    if(props.data === "ew_claimed"){
      history.push('/incident_report/' + row.id + "&" + row.imei_no)
    }
    else{
      history.push('/incident_sc_report/' + row.id + "&" + row.imei_no)
    }
    
  }

  const onClickDownloadIR = (row) => {

    
  }

  const onClickCheckOutReceipt = (row) => {
    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(false)
    setDialogTitle("Check Out Receipt")
    setOptions("check_out_receipt")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickPreview = (row) => {

    if(row.type === "application/pdf")
    {
      window.open(row.url, "_blank")
    }
    else
    {
      setDialogLoading(true)
      setSelectedItem(row)
      setAgreeButton(false)
      setDialogTitle("Preview")
      setOptions("preview")
      setDialogDes("")
      setFullScreenDialog(false)
      setOpenDialog(true)
    }

  }

  const onClickProofOfPayment = (row) => {

    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(false)
    setDialogTitle("Proof of Payment")
    setOptions("preview_proof_of_payment")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onMediaReady = (e) => {
    setDialogLoading(false)
  }

  const onClickMove = (row) => {

    setDialogLoading(true)
    let itemRef = firebase.database().ref(props.folder_data);
    
    itemRef.once('value', snapshot => {
        
      if(snapshot.exists()){
        let items = [];
        snapshot.forEach(child => {
            
          var itemsVal = child.val();
          items.push(itemsVal);
          
        })
        
        setFolderData(items)
        setDialogLoading(false)
      }
      else
      {
        setFolderData([])
        setDialogLoading(false)
      }

    })

    setAgreeButton(false)
    setSelectedItem(row)
    setDialogTitle("Move")
    setOptions("move")
    setDialogDes("Are you sure you want to move this item?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickEdit = (row) => {
    setDialogLoading(false)
    setAgreeButton(true)
    setSelectedItem(row)
    setDialogTitle("Edit")
    setOptions("edit")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const onClickAddItem = () => {
    setDialogLoading(false)
    setSelectedItem(null)
    setAgreeButton(true)
    setDialogTitle(props.addItemText)
    setOptions("addItem")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const onClickSyncItem = () => {
    setDialogLoading(false)
    setSelectedItem(null)
    setAgreeButton(true)
    setDialogTitle("Sync Products")
    setOptions("syncItem")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickOpenFolder = (row) => {
    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(false)
    setDialogTitle(row.name)
    setOptions("openFolder")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  };

  const selectFolder = (cell) => {
    firebase.database().ref(props.data + '/' + selectedItem.id).update({ folder: cell ? cell.id : null })
    if(selectedItem.folder){
      firebase.database().ref(props.folder_data + "/" + selectedItem.folder).update({ updated: firebase.database.ServerValue.TIMESTAMP})
    }
    
    setSelected([])
    setOpenDialog(false)
  }

  const selectFolderMoveAll = (cell) => {

    selected.forEach((child, index) => {
      firebase.database().ref(props.data + '/' + child.id).update({ folder: cell ? cell.id : null })
      if(child.folder){
        firebase.database().ref(props.folder_data + "/" + child.folder).update({ updated: firebase.database.ServerValue.TIMESTAMP})
      }
    });

    setSelected([])
    setOpenDialog(false)
  }

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  const handleAgree = () => {
    
    if(option === "deleteAll"){
        selected.forEach((child, index) => {

          

          if(props.data === "pos/" + firebase.auth().currentUser.displayName + "/products"){

            var currentProductIndex = props.productItems.map(val => val.id).indexOf(child.id)

            if(currentProductIndex !== -1){
            
              props.productItems.splice(currentProductIndex, 1);
              props.setProductItem(props.productItems)
            }
          }
          


          firebase.database().ref(props.data + '/' + child.id).remove().then(() => {
            if(props.storage){
              firebase.storage().ref().child(child.full_path).delete().catch((error)=>{

              })
            }
          }).then(()=>{ 
            if(child.notification_id){
                firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/notification/" + child.notification_id).update({ read: true });
            }
          })

          if(props.type === "folder"){
            firebase.database().ref(props.child).once('value', snapshot => {
              if(snapshot.exists()){
                snapshot.forEach(childval => {
              
                  var itemsVal = childval.val();
                  if(itemsVal.folder && 
                    itemsVal.folder === child.id){
                      firebase.database().ref(props.child + '/' + itemsVal.id).remove().then(() => {
  
                        if(props.storage){
                          firebase.storage().ref().child(itemsVal.full_path).delete().catch((error)=>{
             
                          })
                        }
                        
                      });
                      
                  }
                  
                })
              }
            })
            
          }

        });


    }

    else if(option === "delete"){


      firebase.database().ref(props.data + '/' + selectedItem.id).remove().then(() => {
        if(props.storage){
          firebase.storage().ref().child(selectedItem.full_path).delete()
          .catch((error)=>{
           
          })
        }
      
        
        if(props.type === "folder"){
          firebase.database().ref(props.child).once('value', snapshot => {
            if(snapshot.exists()){
              snapshot.forEach(child => {
            
                var itemsVal = child.val();
                if(itemsVal.folder && 
                  itemsVal.folder === selectedItem.id){
                    firebase.database().ref(props.child + '/' + itemsVal.id).remove().then(() => {

                      if(props.storage){
                        firebase.storage().ref().child(itemsVal.full_path).delete().catch((error)=>{
           
                        })
                      }
                      
                    });
                    
                }
                
              })
            }
          })
          
        }

      }).then(()=>{ 
        if(selectedItem.notification_id){
            firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/notification/" + selectedItem.notification_id).update({ read: true });
        }
      })
    }    

    else if(option === "edit"){
     
      firebase.database().ref(props.data + '/' + selectedItem.id).update(selectedItem)
    }    

    else if(option === "addItem"){

        var newPostKey = firebase.database().ref().child(props.data).push().key;

        addItem['created'] = firebase.database.ServerValue.TIMESTAMP;
        addItem['id'] = newPostKey;
        addItem['user'] = firebase.auth().currentUser.email;

        if(props.folder){
          addItem['folder'] = props.folder;
          firebase.database().ref(props.folder_data + "/" + props.folder).update({ updated: firebase.database.ServerValue.TIMESTAMP})
        }

        var updates = {};
        updates[props.data + '/' + newPostKey] = addItem;

        firebase.database().ref().update(updates);
        setAddItem(props.addCellsName ? props.addCellsName : [])
        
    }
    else if(option === "syncItem"){

      firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/products")
      .once('value', snapshot => {
        if(snapshot.exists()){

          let items_products = [];

          snapshot.forEach(child => {
            var itemsVal = child.val();
            items_products.push(itemsVal)
          })

          let itemRef = firebase.database().ref("products");

          itemRef.once('value', snapshot => {
            if(snapshot.exists()){

              let items = [];
              snapshot.forEach(child => {
                
                var itemsVal = child.val();

                var currentIndex = items_products.map(val => val.id).indexOf(itemsVal.id)

                if(currentIndex !== -1){
                  itemsVal.in_stock = items_products[currentIndex].in_stock ? items_products[currentIndex].in_stock : "0";
                  itemsVal.retail_price = items_products[currentIndex].retail_price ? items_products[currentIndex].retail_price : "0";
                  itemsVal.discount_price = items_products[currentIndex].discount_price ? items_products[currentIndex].discount_price : "0";
                  itemsVal.cost_price = items_products[currentIndex].cost_price ? items_products[currentIndex].cost_price : "0";
                }
                else{
                  itemsVal.in_stock = 0;
                  itemsVal.retail_price = itemsVal.retail_price ? itemsVal.retail_price : "0";
                  itemsVal.discount_price = itemsVal.discount_price ? itemsVal.discount_price : "0";
                  itemsVal.cost_price = itemsVal.cost_price ? itemsVal.cost_price : "0";
                }
               
                if(itemsVal.status === "published")
                {
                  var updates = {};
                  updates[itemsVal.id] = itemsVal;
                  items = { ...items, ...updates};
                }

              })

              firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/products").update(items)

              let itemCategoriesRef = firebase.database().ref("categories");
      
              itemCategoriesRef.once('value', snapshot => {

                if(snapshot.exists()){
                  
                  let items_cate = [];
                  snapshot.forEach(val => {

                    var itemsVal_cate = val.val();

                    var updates_cate = {};
                    updates_cate[itemsVal_cate.id] = itemsVal_cate;
                    items_cate = { ...items_cate, ...updates_cate};

                  })

                  firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/categories").update(items_cate)
                }

              });
              
            }
          })
              
        }
        else
        {
          let itemRef = firebase.database().ref("products");

          itemRef.once('value', snapshot => {
            if(snapshot.exists()){
    
              let items = [];
              snapshot.forEach(child => {
                
                var itemsVal = child.val();
                itemsVal.in_stock = 0;
                itemsVal.retail_price = itemsVal.retail_price ? itemsVal.retail_price : "0";
                itemsVal.discount_price = itemsVal.discount_price ? itemsVal.discount_price : "0";
                itemsVal.cost_price = itemsVal.cost_price ? itemsVal.cost_price : "0";
                if(itemsVal.status === "published")
                {
                  var updates = {};
                  updates[itemsVal.id] = itemsVal;
                  items = { ...items, ...updates};
                }
    
              })
    
              firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/products").update(items)
    
              let itemCategoriesRef = firebase.database().ref("categories");
      
              itemCategoriesRef.once('value', snapshot => {
    
                if(snapshot.exists()){
                  
                  let items_cate = [];
                  snapshot.forEach(val => {
    
                    var itemsVal_cate = val.val();
    
                    var updates_cate = {};
                    updates_cate[itemsVal_cate.id] = itemsVal_cate;
                    items_cate = { ...items_cate, ...updates_cate};
    
                  })
    
                  firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/categories").update(items_cate)
                }
    
              });
              
            }
          })
        }
      })

      
      
    }
    else if (option === "uploadExcel") {

      if(fileUpload){

        let _array = {}
        
        readXlsxFile(fileUpload).then((rows) => {
          //var time = 5;
          rows.splice(2).forEach((item) => {

           if(props.data === "imei"){
            if (item[0]) {
              let items = {
                imei_no: item[0] ? item[0] : null,
                  id: item[0] ? item[0] : null,
                  batch: item[1] ? item[1] : null,
                  capacity: item[2] ? item[2] : null,
                  ew_service_plan_expired_date: item[3] ? moment(item[3]).toISOString() : null,
                  ew_service_plan_start_date: item[4] ? moment(item[4]).toISOString() : null,
                  extended_warranty: item[5] ? item[5] : null,
                  physical_device_brand: item[6] ? item[6] : null,
                  physical_device_model_no: item[7] ? item[7] : null,
                  physical_device_serial_no: item[8] ? item[8] : null,
                  physical_device_srp_price: item[9] ? item[9] : null,
                  plan: item[10] ? item[10] : null,
                  premium_amount: item[11] ? item[11] : null,
                  repair_cost_limit: item[12] ? item[12] : null,
                  service_plan_purchase_date: item[13] ? moment(item[13]).toISOString() : null,
              }

              _array[item[0]] = items;
            }
           }
           else if(props.data === "screen_crack")
           {
            if (item[0]) {
              let items = {
                  imei_no: item[0] ? item[0] : null,
                  id: item[0] ? item[0] : null,
                  batch: item[1] ? item[1] : null,
                  capacity: item[2] ? item[2] : null,
                  sc_service_plan_expired_date: item[3] ? moment(item[3]).toISOString() : null,
                  sc_service_plan_start_date: item[4] ? moment(item[4]).toISOString() : null,
                  extended_warranty: item[5] ? item[5] : null,
                  physical_device_brand: item[6] ? item[6] : null,
                  physical_device_model_no: item[7] ? item[7] : null,
                  physical_device_serial_no: item[8] ? item[8] : null,
                  physical_device_srp_price: item[9] ? item[9] : null,
                  plan: item[10] ? item[10] : null,
                  premium_amount: item[11] ? item[11] : null,
                  repair_cost_limit: item[12] ? item[12] : null,
                  sc_service_plan_purchase_date: item[13] ? moment(item[13]).toISOString() : null,
              }

              _array[item[0]] = items;
            }
           }
            
            
          })

          //console.log(_array)
          if(props.data === "imei")
          {
            firebase.database().ref('imei').update(_array)
          }
          else if(props.data === "screen_crack")
          {
            firebase.database().ref('screen_crack').update(_array)
          }
          

       })

      }

      setFileUploaded(null)
      
    }

    else if (option === "updateAllApp"){
      rowsOriginal.forEach(cell => {
        firebase.database().ref('station/' + cell.id).update({ push: true, message: "push", volume: "0" });
      })
    }

    else if (option === "downloadExcel") {

      if (props.excelList) {

        var listItems = {
          columns: [],
          data: [[]]
        }

        props.excelList.forEach(cell => {

          if (cell.id === "id" || cell.id === "name" || cell.id === "type"
            || cell.id === "zone" || cell.id === "address" || cell.id === "created" || cell.id === "refreshed"
            || cell.id === "block" || cell.id === "note") {
            listItems.columns.push({ title: "", width: { wpx: 170 } })
          }
          else if (cell.id === "imei_no") {
            listItems.columns.push({ title: "", width: { wpx: 270 } })
          }
          else if (cell.id === "submit_user") {
            listItems.columns.push({ title: "", width: { wpx: 170 } })
          }
          else {
            listItems.columns.push({ title: "", width: { wpx: cell.label.length * 12 } })
          }

          listItems.data[0].push({
            value: cell.label,
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "11", bold: true },
              border: {
                top: { style: "thin", color: { rgb: "FF000000" } },
                bottom: { style: "thin", color: { rgb: "FF000000" } },
                left: { style: "thin", color: { rgb: "FF000000" } },
                right: { style: "thin", color: { rgb: "FF000000" } }
              },
              fill: { patternType: "solid", fgColor: { rgb: "FFffd200" } }
            }
          })

        })

        /*if(offlineList){
          readrowsExcel().forEach((cell) => {
            var items_cell = []
            if(!cell.temporary_removed_date){
              if(!cell.faulty_date)
              {
                if(!cell.low_signal)
                {
                  if(!cell.no_power)
                  {
                    if(!cell.no_signal)
                    {
                      if(cell.status ===false){
  
                        props.excelList.forEach(cellid => {
  
                          if (cellid.id === "created" || cellid.id === "updated" || cellid.id === "signed_in" || cellid.id === "signed_out") {
              
                            var created_date = "";
              
                            if (cell[cellid.id]) {
                              created_date = new Intl.DateTimeFormat('en-US', { year: 'numeric', day: '2-digit', month: 'long', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }).format(cell[cellid.id]).toString()
                            }
              
              
                            items_cell.push(
                              {
                                value: created_date,
                                style: {
                                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                                  font: { sz: "11" },
                                  border: {
                                    top: { style: "thin", color: { rgb: "FF000000" } },
                                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                                    left: { style: "thin", color: { rgb: "FF000000" } },
                                    right: { style: "thin", color: { rgb: "FF000000" } }
                                  }
                                }
                              }
                            )
                          }
              
                          else if (cellid.id === "content_start_date" || cellid.id === "content_end_date") {
              
                            var created_date_2 = "";
              
                            if (cell["item"]["start_date"] && cellid.id === "content_start_date") {
                              var d = new Date(cell["item"]["start_date"])
                              created_date_2 = new Intl.DateTimeFormat('en-US', {
                                year: 'numeric', day: '2-digit', month: 'long'
                              }).format(d).toString()
                            }
              
                            if (cell["item"]["end_date"] && cellid.id === "content_end_date") {
                              var d2 = new Date(cell["item"]["end_date"])
                              created_date_2 = new Intl.DateTimeFormat('en-US', {
                                year: 'numeric', day: '2-digit', month: 'long'
                              }).format(d2).toString()
                            }
              
              
                            items_cell.push(
                              {
                                value: created_date_2,
                                style: {
                                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                                  font: { sz: "11" },
                                  border: {
                                    top: { style: "thin", color: { rgb: "FF000000" } },
                                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                                    left: { style: "thin", color: { rgb: "FF000000" } },
                                    right: { style: "thin", color: { rgb: "FF000000" } }
                                  }
                                }
                              }
                            )
                          }
              
                          else if (cellid.id === "content_start_time" || cellid.id === "content_end_time") {
              
                            var created_date_3 = "";
              
                            if (cell["item"]["start_time"] && cellid.id === "content_start_time") {
                              var d4 = new Date(cell["item"]["start_time"])
                              created_date_3 = new Intl.DateTimeFormat('en-US', {
                                hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true
                              }).format(d4).toString()
                            }
              
                            if (cell["item"]["end_time"] && cellid.id === "content_end_time") {
                              var d3 = new Date(cell["item"]["end_time"])
                              created_date_3 = new Intl.DateTimeFormat('en-US', {
                                hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true
                              }).format(d3).toString()
                            }
              
              
                            items_cell.push(
                              {
                                value: created_date_3,
                                style: {
                                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                                  font: { sz: "11" },
                                  border: {
                                    top: { style: "thin", color: { rgb: "FF000000" } },
                                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                                    left: { style: "thin", color: { rgb: "FF000000" } },
                                    right: { style: "thin", color: { rgb: "FF000000" } }
                                  }
                                }
                              }
                            )
                          }
              
              
                          else if (cellid.id === "refreshed") {
              
                            var refreshed_date = "";
              
                            if (cell[cellid.id]) {
                              refreshed_date = new Intl.DateTimeFormat('en-US', { year: 'numeric', day: '2-digit', month: 'long', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }).format(cell[cellid.id]).toString()
                            }
              
                            items_cell.push(
                              {
                                value: refreshed_date,
                                style: {
                                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                                  font: { sz: "11" },
                                  border: {
                                    top: { style: "thin", color: { rgb: "FF000000" } },
                                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                                    left: { style: "thin", color: { rgb: "FF000000" } },
                                    right: { style: "thin", color: { rgb: "FF000000" } }
                                  }
                                }
                              }
                            )
                          }
                          else if (cellid.id === "size" || cellid.id === "campaign_size") {
              
                            var fileSize = "";
              
                            if (cell[cellid.id]) {
                              fileSize = bytesToSize(cell[cellid.id])
                            }
              
              
                            items_cell.push(
                              {
                                value: fileSize,
                                style: {
                                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                                  font: { sz: "11" },
                                  border: {
                                    top: { style: "thin", color: { rgb: "FF000000" } },
                                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                                    left: { style: "thin", color: { rgb: "FF000000" } },
                                    right: { style: "thin", color: { rgb: "FF000000" } }
                                  }
                                }
                              }
                            )
                          }
                          else if (cellid.id === "campaign_duration") {
              
                            var campaign_duration = ""
              
                            if (cell[cellid.id]) {
                              campaign_duration = secondsToHms(cell[cellid.id])
                            }
              
              
                            items_cell.push(
                              {
                                value: campaign_duration,
                                style: {
                                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                                  font: { sz: "11" },
                                  border: {
                                    top: { style: "thin", color: { rgb: "FF000000" } },
                                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                                    left: { style: "thin", color: { rgb: "FF000000" } },
                                    right: { style: "thin", color: { rgb: "FF000000" } }
                                  }
                                }
                              }
                            )
                          }
              
                          else if (cellid.id === "publish") {
              
                            var publish = "Not yet"
              
                            if (cell[cellid.id]) {
                              publish = "Yes"
                            }
              
              
                            items_cell.push(
                              {
                                value: publish,
                                style: {
                                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                                  font: { sz: "11" },
                                  border: {
                                    top: { style: "thin", color: { rgb: "FF000000" } },
                                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                                    left: { style: "thin", color: { rgb: "FF000000" } },
                                    right: { style: "thin", color: { rgb: "FF000000" } }
                                  }
                                }
                              }
                            )
                          }
              
                          else if (cellid.id === "campaign_status") {
              
                            var campaign_status = "Permanent"
              
                            if (cell[cellid.id] !== null) {
              
                              if (cell[cellid.id]) {
                                campaign_status = "Not Expired"
                              }
                              else {
                                campaign_status = "Expired"
                              }
              
                            }
              
              
                            items_cell.push(
                              {
                                value: campaign_status,
                                style: {
                                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                                  font: { sz: "11" },
                                  border: {
                                    top: { style: "thin", color: { rgb: "FF000000" } },
                                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                                    left: { style: "thin", color: { rgb: "FF000000" } },
                                    right: { style: "thin", color: { rgb: "FF000000" } }
                                  }
                                }
                              }
                            )
                          }
                          else if (cellid.id === "file_name") {
              
                            var File_name = cell["item"]["name"]
              
                            items_cell.push(
                              {
                                value: File_name,
                                style: {
                                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                                  font: { sz: "11" },
                                  border: {
                                    top: { style: "thin", color: { rgb: "FF000000" } },
                                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                                    left: { style: "thin", color: { rgb: "FF000000" } },
                                    right: { style: "thin", color: { rgb: "FF000000" } }
                                  }
                                }
                              }
                            )
                          }
              
                          else if (cellid.id === "stations") {
              
                            var _folders = []
                            var stations = ""
              
                            if (cell[cellid.id] !== undefined && cell[cellid.id] !== null) {
                              cell[cellid.id].forEach(val => {
              
                                if (val.folder !== undefined) {
                                  _folders.push(val.folder)
                                }
                              })
              
              
              
                            }
              
              
                            items_cell.push(
                              {
                                value: stations,
                                style: {
                                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                                  font: { sz: "11" },
                                  border: {
                                    top: { style: "thin", color: { rgb: "FF000000" } },
                                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                                    left: { style: "thin", color: { rgb: "FF000000" } },
                                    right: { style: "thin", color: { rgb: "FF000000" } }
                                  }
                                }
                              }
                            )
                          }
              
                          else if (cellid.id === "content_campaign") {
              
                            var campaigns = ""
              
                            if (cell[cellid.id] !== undefined && cell[cellid.id] !== null) {
                              campaigns = cell[cellid.id]
                            }
              
                            items_cell.push(
                              {
                                value: campaigns,
                                style: {
                                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                                  font: { sz: "11" },
                                  border: {
                                    top: { style: "thin", color: { rgb: "FF000000" } },
                                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                                    left: { style: "thin", color: { rgb: "FF000000" } },
                                    right: { style: "thin", color: { rgb: "FF000000" } }
                                  }
                                }
                              }
                            )
                          }
              
                          else {
                            items_cell.push(
                              {
                                value: cell[cellid.id] ? cell[cellid.id] : "",
                                style: {
                                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                                  font: { sz: "11" },
                                  border: {
                                    top: { style: "thin", color: { rgb: "FF000000" } },
                                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                                    left: { style: "thin", color: { rgb: "FF000000" } },
                                    right: { style: "thin", color: { rgb: "FF000000" } }
                                  }
                                }
                              }
                            )
                          }
              
                        })

                        console.log(items_cell)
                        listItems.data.push(items_cell)
                      }
                      
                      
                    }
                  }
                }
              }
            }
          })
        }
        else
        {
          readrowsExcel().forEach((cell) => {
            var items_cell = []
            
            props.excelList.forEach(cellid => {
  
            if (cellid.id === "created" || cellid.id === "updated" || cellid.id === "signed_in" || cellid.id === "signed_out") {
  
                var created_date = "";
  
                if (cell[cellid.id]) {
                  created_date = new Intl.DateTimeFormat('en-US', { year: 'numeric', day: '2-digit', month: 'long', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }).format(cell[cellid.id]).toString()
                }
  
  
                items_cell.push(
                  {
                    value: created_date,
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }

              else if (cellid.id === "ew_service_plan_expired_date" || cellid.id === "ew_service_plan_start_date"
              || cellid.id === "service_plan_purchase_date" || cellid.id === "sc_service_plan_expired_date" || cellid.id === "sc_service_plan_start_date"
              || cellid.id === "sc_service_plan_purchase_date") {
  
                var created_date = "";
  
                if (cell[cellid.id]) {
                  created_date = moment(cell[cellid.id]).format('YYYY-MM-DD');
                }
  
  
                items_cell.push(
                  {
                    value: created_date,
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }

              else if (cellid.id === "submit_user") {
  
  
                items_cell.push(
                  {
                    value: cell[cellid.id] && cell[cellid.id].id ? cell[cellid.id].id : "-",
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }
              else if (cellid.id === "purchase_invoice" || 
              cellid.id === "pre_repair_image" || 
              cellid.id === "post_repair_image" ||
              cellid.id === "repair_invoice" ||
              cellid.id === "service_repair_report" ) {
  
  
                items_cell.push(
                  {
                    value: cell[cellid.id] && cell[cellid.id].url ? cell[cellid.id].url : "-",
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }

              else if (cellid.id === "service_center_name") {
  
  
                items_cell.push(
                  {
                    value: cell["submit_user"] && cell["submit_user"].service_center_id ? cell["submit_user"].service_center_id : "-",
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }
  
              else if (cellid.id === "content_start_time" || cellid.id === "content_end_time") {
  
                var created_date_3 = "";
  
                if (cell["item"]["start_time"] && cellid.id === "content_start_time") {
                  var d4 = new Date(cell["item"]["start_time"])
                  created_date_3 = new Intl.DateTimeFormat('en-US', {
                    hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true
                  }).format(d4).toString()
                }
  
                if (cell["item"]["end_time"] && cellid.id === "content_end_time") {
                  var d3 = new Date(cell["item"]["end_time"])
                  created_date_3 = new Intl.DateTimeFormat('en-US', {
                    hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true
                  }).format(d3).toString()
                }
  
  
                items_cell.push(
                  {
                    value: created_date_3,
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }
  
  
              else if (cellid.id === "refreshed") {
  
                var refreshed_date = "";
  
                if (cell[cellid.id]) {
                  refreshed_date = new Intl.DateTimeFormat('en-US', { year: 'numeric', day: '2-digit', month: 'long', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }).format(cell[cellid.id]).toString()
                }
  
                items_cell.push(
                  {
                    value: refreshed_date,
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }
              else if (cellid.id === "size" || cellid.id === "campaign_size") {
  
                var fileSize = "";
  
                if (cell[cellid.id]) {
                  fileSize = bytesToSize(cell[cellid.id])
                }
  
  
                items_cell.push(
                  {
                    value: fileSize,
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }
              else if (cellid.id === "campaign_duration") {
  
                var campaign_duration = ""
  
                if (cell[cellid.id]) {
                  campaign_duration = secondsToHms(cell[cellid.id])
                }
  
  
                items_cell.push(
                  {
                    value: campaign_duration,
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }
  
              else if (cellid.id === "publish") {
  
                var publish = "Not yet"
  
                if (cell[cellid.id]) {
                  publish = "Yes"
                }
  
  
                items_cell.push(
                  {
                    value: publish,
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }
  
              else if (cellid.id === "campaign_status") {
  
                var campaign_status = "Permanent"
  
                if (cell[cellid.id] !== null) {
  
                  if (cell[cellid.id]) {
                    campaign_status = "Not Expired"
                  }
                  else {
                    campaign_status = "Expired"
                  }
  
                }
  
  
                items_cell.push(
                  {
                    value: campaign_status,
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }
              else if (cellid.id === "file_name") {
  
                var File_name = cell["item"]["name"]
  
                items_cell.push(
                  {
                    value: File_name,
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }
  
  
              else {
                items_cell.push(
                  {
                    value: cell[cellid.id] ? cell[cellid.id] : "",
                    style: {
                      alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                      font: { sz: "11" },
                      border: {
                        top: { style: "thin", color: { rgb: "FF000000" } },
                        bottom: { style: "thin", color: { rgb: "FF000000" } },
                        left: { style: "thin", color: { rgb: "FF000000" } },
                        right: { style: "thin", color: { rgb: "FF000000" } }
                      }
                    }
                  }
                )
              }
  
            })
  
  
            
            listItems.data.push(items_cell)
          })
        }

       

        setMultiDataSet([listItems])

        setTimeout(
          () => excelInput.current.click(),
          1000
        );*/

        if(props.data === "sc_claimed" || props.data === "ew_claimed" || 
          props.data === "screen_crack" || props.data === "imei"
        )
        {
          let itemRef = firebase.database().ref(props.data);

          itemRef.on('value', snapshot => {
          
            if(snapshot.exists()){
      
              let items = [];
      
              snapshot.forEach(child => {
  
                var itemsVal = child.val();
              
                props.headCells.forEach(headChild =>{
                  itemsVal[headChild.id] = itemsVal[headChild.id] ? itemsVal[headChild.id] : ''
                })
  
                var _itemsVal = Object.values(itemsVal);
              
                var _itemsVal2 = Object.fromEntries(
                  Object.entries(_itemsVal)
                    .filter(item => item[1] !== "")
                );
  
                var _itemsVal3 = Object.values(_itemsVal2)
                
                _itemsVal3.forEach(val =>{
  
                  if(props.data === "sc_claimed" || props.data === "ew_claimed")
                    {
                      val.fully_submitted = val.repair_date ? "Yes" : "No";
                    }
                 
                  items.push(val)
                 
                 
                })
              })
  
              //console.log(items)
              var items_cell2 = []
              readrowsExcel().forEach((cell) => {
                
                /*var currentIndex = items.map(val=>val.imei_no).indexOf(cell.imei_no);
  
                if(currentIndex != -1){
                  cell.fully_submitted = items[currentIndex].fully_submitted;
                  cell.repair_amount = items[currentIndex].repair_amount ? items[currentIndex].repair_amount : 0;
                  cell.repair_date = items[currentIndex].repair_date ? items[currentIndex].repair_date : "-";
                  cell.incident_detail = items[currentIndex].incident_detail ? items[currentIndex].incident_detail : "-";
                  cell.status = items[currentIndex].status ? items[currentIndex].status : "-";
                }
                else
                {
                  cell.fully_submitted = "none";
                  cell.repair_amount = 0;
                  cell.repair_date = "-";
                  cell.incident_detail = "-";
                  cell.status = "-";
                }*/
  
                items_cell2.push(cell);
              })
  
              items_cell2.forEach((cell) => {
                var items_cell = []
                
                props.excelList.forEach(cellid => {
      
                if (cellid.id === "created" || cellid.id === "updated" || cellid.id === "signed_in" || cellid.id === "signed_out") {
      
                    var created_date = "";
      
                    if (cell[cellid.id]) {
                      created_date = new Intl.DateTimeFormat('en-US', { year: 'numeric', day: '2-digit', month: 'long', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }).format(cell[cellid.id]).toString()
                    }
      
      
                    items_cell.push(
                      {
                        value: created_date,
                        style: {
                          alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                          font: { sz: "11" },
                          border: {
                            top: { style: "thin", color: { rgb: "FF000000" } },
                            bottom: { style: "thin", color: { rgb: "FF000000" } },
                            left: { style: "thin", color: { rgb: "FF000000" } },
                            right: { style: "thin", color: { rgb: "FF000000" } }
                          }
                        }
                      }
                    )
                  }
    
                  else if (cellid.id === "ew_service_plan_expired_date" || cellid.id === "ew_service_plan_start_date"
                  || cellid.id === "service_plan_purchase_date" || cellid.id === "sc_service_plan_expired_date" || cellid.id === "sc_service_plan_start_date"
                  || cellid.id === "sc_service_plan_purchase_date") {
      
                    var created_date = "";
      
                    if (cell[cellid.id]) {
                      created_date = moment(cell[cellid.id]).format('YYYY-MM-DD');
                    }
      
      
                    items_cell.push(
                      {
                        value: created_date,
                        style: {
                          alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                          font: { sz: "11" },
                          border: {
                            top: { style: "thin", color: { rgb: "FF000000" } },
                            bottom: { style: "thin", color: { rgb: "FF000000" } },
                            left: { style: "thin", color: { rgb: "FF000000" } },
                            right: { style: "thin", color: { rgb: "FF000000" } }
                          }
                        }
                      }
                    )
                  }
    
                  else if (cellid.id === "submit_user") {
      
      
                    items_cell.push(
                      {
                        value: cell[cellid.id] && cell[cellid.id].id ? cell[cellid.id].id : "-",
                        style: {
                          alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                          font: { sz: "11" },
                          border: {
                            top: { style: "thin", color: { rgb: "FF000000" } },
                            bottom: { style: "thin", color: { rgb: "FF000000" } },
                            left: { style: "thin", color: { rgb: "FF000000" } },
                            right: { style: "thin", color: { rgb: "FF000000" } }
                          }
                        }
                      }
                    )
                  }
                  else if (cellid.id === "purchase_invoice" || 
                  cellid.id === "pre_repair_image" || 
                  cellid.id === "post_repair_image" ||
                  cellid.id === "repair_invoice" ||
                  cellid.id === "service_repair_report" ) {
      
      
                    items_cell.push(
                      {
                        value: cell[cellid.id] && cell[cellid.id].url ? cell[cellid.id].url : "-",
                        style: {
                          alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                          font: { sz: "11" },
                          border: {
                            top: { style: "thin", color: { rgb: "FF000000" } },
                            bottom: { style: "thin", color: { rgb: "FF000000" } },
                            left: { style: "thin", color: { rgb: "FF000000" } },
                            right: { style: "thin", color: { rgb: "FF000000" } }
                          }
                        }
                      }
                    )
                  }
    
                  else if (cellid.id === "service_center_name") {
      
                    
                    var currentIndex = allUser.map(val => val.id).indexOf(cell["submit_user"].id);

                    if(currentIndex !== -1){
                      items_cell.push(
                        {
                          value: allUser[currentIndex].service_center_id,
                          style: {
                            alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                            font: { sz: "11" },
                            border: {
                              top: { style: "thin", color: { rgb: "FF000000" } },
                              bottom: { style: "thin", color: { rgb: "FF000000" } },
                              left: { style: "thin", color: { rgb: "FF000000" } },
                              right: { style: "thin", color: { rgb: "FF000000" } }
                            }
                          }
                        }
                      )
                    }
                    else{
                      items_cell.push(
                        {
                          value: cell["submit_user"] ? cell["submit_user"].service_center_id : "-",
                          style: {
                            alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                            font: { sz: "11" },
                            border: {
                              top: { style: "thin", color: { rgb: "FF000000" } },
                              bottom: { style: "thin", color: { rgb: "FF000000" } },
                              left: { style: "thin", color: { rgb: "FF000000" } },
                              right: { style: "thin", color: { rgb: "FF000000" } }
                            }
                          }
                        }
                      )
                    }
      
                    
                  }

      
                  else if (cellid.id === "content_start_time" || cellid.id === "content_end_time") {
      
                    var created_date_3 = "";
      
                    if (cell["item"]["start_time"] && cellid.id === "content_start_time") {
                      var d4 = new Date(cell["item"]["start_time"])
                      created_date_3 = new Intl.DateTimeFormat('en-US', {
                        hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true
                      }).format(d4).toString()
                    }
      
                    if (cell["item"]["end_time"] && cellid.id === "content_end_time") {
                      var d3 = new Date(cell["item"]["end_time"])
                      created_date_3 = new Intl.DateTimeFormat('en-US', {
                        hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true
                      }).format(d3).toString()
                    }
      
      
                    items_cell.push(
                      {
                        value: created_date_3,
                        style: {
                          alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                          font: { sz: "11" },
                          border: {
                            top: { style: "thin", color: { rgb: "FF000000" } },
                            bottom: { style: "thin", color: { rgb: "FF000000" } },
                            left: { style: "thin", color: { rgb: "FF000000" } },
                            right: { style: "thin", color: { rgb: "FF000000" } }
                          }
                        }
                      }
                    )
                  }
      
      
                  else if (cellid.id === "refreshed") {
      
                    var refreshed_date = "";
      
                    if (cell[cellid.id]) {
                      refreshed_date = new Intl.DateTimeFormat('en-US', { year: 'numeric', day: '2-digit', month: 'long', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }).format(cell[cellid.id]).toString()
                    }
      
                    items_cell.push(
                      {
                        value: refreshed_date,
                        style: {
                          alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                          font: { sz: "11" },
                          border: {
                            top: { style: "thin", color: { rgb: "FF000000" } },
                            bottom: { style: "thin", color: { rgb: "FF000000" } },
                            left: { style: "thin", color: { rgb: "FF000000" } },
                            right: { style: "thin", color: { rgb: "FF000000" } }
                          }
                        }
                      }
                    )
                  }
                  else if (cellid.id === "size" || cellid.id === "campaign_size") {
      
                    var fileSize = "";
      
                    if (cell[cellid.id]) {
                      fileSize = bytesToSize(cell[cellid.id])
                    }
      
      
                    items_cell.push(
                      {
                        value: fileSize,
                        style: {
                          alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                          font: { sz: "11" },
                          border: {
                            top: { style: "thin", color: { rgb: "FF000000" } },
                            bottom: { style: "thin", color: { rgb: "FF000000" } },
                            left: { style: "thin", color: { rgb: "FF000000" } },
                            right: { style: "thin", color: { rgb: "FF000000" } }
                          }
                        }
                      }
                    )
                  }
                  else if (cellid.id === "campaign_duration") {
      
                    var campaign_duration = ""
      
                    if (cell[cellid.id]) {
                      campaign_duration = secondsToHms(cell[cellid.id])
                    }
      
      
                    items_cell.push(
                      {
                        value: campaign_duration,
                        style: {
                          alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                          font: { sz: "11" },
                          border: {
                            top: { style: "thin", color: { rgb: "FF000000" } },
                            bottom: { style: "thin", color: { rgb: "FF000000" } },
                            left: { style: "thin", color: { rgb: "FF000000" } },
                            right: { style: "thin", color: { rgb: "FF000000" } }
                          }
                        }
                      }
                    )
                  }
      
                  else if (cellid.id === "publish") {
      
                    var publish = "Not yet"
      
                    if (cell[cellid.id]) {
                      publish = "Yes"
                    }
      
      
                    items_cell.push(
                      {
                        value: publish,
                        style: {
                          alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                          font: { sz: "11" },
                          border: {
                            top: { style: "thin", color: { rgb: "FF000000" } },
                            bottom: { style: "thin", color: { rgb: "FF000000" } },
                            left: { style: "thin", color: { rgb: "FF000000" } },
                            right: { style: "thin", color: { rgb: "FF000000" } }
                          }
                        }
                      }
                    )
                  }
      
                  else if (cellid.id === "campaign_status") {
      
                    var campaign_status = "Permanent"
      
                    if (cell[cellid.id] !== null) {
      
                      if (cell[cellid.id]) {
                        campaign_status = "Not Expired"
                      }
                      else {
                        campaign_status = "Expired"
                      }
      
                    }
      
      
                    items_cell.push(
                      {
                        value: campaign_status,
                        style: {
                          alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                          font: { sz: "11" },
                          border: {
                            top: { style: "thin", color: { rgb: "FF000000" } },
                            bottom: { style: "thin", color: { rgb: "FF000000" } },
                            left: { style: "thin", color: { rgb: "FF000000" } },
                            right: { style: "thin", color: { rgb: "FF000000" } }
                          }
                        }
                      }
                    )
                  }
                  else if (cellid.id === "file_name") {
      
                    var File_name = cell["item"]["name"]
      
                    items_cell.push(
                      {
                        value: File_name,
                        style: {
                          alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                          font: { sz: "11" },
                          border: {
                            top: { style: "thin", color: { rgb: "FF000000" } },
                            bottom: { style: "thin", color: { rgb: "FF000000" } },
                            left: { style: "thin", color: { rgb: "FF000000" } },
                            right: { style: "thin", color: { rgb: "FF000000" } }
                          }
                        }
                      }
                    )
                  }
      
      
                  else {
                    items_cell.push(
                      {
                        value: cell[cellid.id] ? cell[cellid.id] : "",
                        style: {
                          alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                          font: { sz: "11" },
                          border: {
                            top: { style: "thin", color: { rgb: "FF000000" } },
                            bottom: { style: "thin", color: { rgb: "FF000000" } },
                            left: { style: "thin", color: { rgb: "FF000000" } },
                            right: { style: "thin", color: { rgb: "FF000000" } }
                          }
                        }
                      }
                    )
                  }
      
                })
      
                listItems.data.push(items_cell)
              })
  
              
              setMultiDataSet([listItems])
  
              setTimeout(
                () => excelInput.current.click(),
                1000
              );
            }
          })
        }

      }

    }


    setSelected([])
    setOpenDialog(false)
  }

  const onChangeHandle = (e, id) => {
    setSelectedItem({ ...selectedItem, [id]: e.target.value });
  }

  const onChangeAddItemHandle = (e, id) => {
    setAddItem({ ...addItem, [id]: e.target.value });
  }

  const onClickDownloadExcel = () => {
    setOfflineList(false)
    setDialogLoading(false)
    setAgreeButton(true)
    setDialogTitle("Download Excel")
    setOptions("downloadExcel")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const readRows = () => {

    var filterData = rowsOriginal.filter(item => { 
      
      if(item.hide)
      {
        return undefined
      }
      else{
        if(item[searchValue] !== undefined){

          return item[searchValue].toString().toLowerCase().includes(searchtxt.toLowerCase())
   
        }
        else
        {
          return undefined
        }
      }
      
    });

    if(searchtxt !== ""){
        return filterData
    }
    else{

      var _filterData = rowsOriginal.filter(item => { 
        if(item.hide)
        {
          return undefined
        }
        else{
          return item
        }
      });

        return _filterData
    }
    
    
  }

  const onChangeUploadFile = (e) => {

    var files = e.target.files
    setFileUploaded(files[0]);

  };

  if(loading){
    return (
    <Grid style={{ display: 'flex',  justifyContent:'center', alignItems:'center',padding: 70 }}>
      <CircularProgress 
        disableShrink
        color="primary" />
    </Grid>);
  }
  
  var d = new Date();
  var datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + "-" +
    d.getHours() + "-" + d.getMinutes() + "-" + d.getSeconds();

  return (
    <Grid>
      <ExcelFile element={<Button style={{ display: "none" }} ref={excelInput} >download</Button>} filename={props.data + "_" + datestring}>
        <ExcelSheet dataSet={multiDataSet} name={props.data} />
      </ExcelFile>
        <Dialog 
          agreeTxt={"Yes"}
          disagreeTxt={"No"}
          description={dialogDes}
          title={dialogTitle}
          open={openDialog} 
          agreeButton={agreeButton}
          selectedItem={selectedItem}
          addItem={addItem}
          onChangeHandle={(e, id) => onChangeHandle(e, id)}
          onChangeAddItemHandle={(e, id) => onChangeAddItemHandle(e, id)}
          handleAgree={handleAgree}
          fullScreenDialog={fullScreenDialog}
          option={option}
          dialogLoading={dialogLoading}
          folderData={folderData}
          selectFolder={selectFolder}
          selectFolderMoveAll={selectFolderMoveAll}
          handleClose={handleClose}
          onMediaReady={onMediaReady}
          onChangeUploadFile={(e) => onChangeUploadFile(e)}
          {...props}/>
          
        <EnhancedTableToolbar 
          onClickMoveAll={onClickMoveAll}
          numSelected={selected.length} 
          selected={selected}
          handleSearchWarranty={handleSearchWarranty}
          handleSearchCustomer={handleSearchCustomer}
          onClickAddItem={onClickAddItem}
          onClickDeleteAll={onClickDeleteAll}
          onClickSyncItem={onClickSyncItem}
          onClickDownloadExcel={onClickDownloadExcel}
          onClickUploadExcel={onClickUploadExcel}
          currentStaff={props.currentStaff}
          {...props}/>

        <TableContainer>
        <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
            >
            <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={readRows().length}
                headCells={headCells}
                {...props}
            />
            
            <TableBody>
                {props.hideSearch ? null: <TableRow>
                    <TableCell padding={'none'} colSpan={props.headCells.length+1+(props.control ? 1 : 0)}>
                      <Grid container>
                          <Grid item md={7} xs={12}>
                          <form noValidate autoComplete="off">
                          <FormControl style={{ marginTop:10 }} fullWidth >
                              <InputLabel style={{ marginLeft:20 }}>Search</InputLabel>
                              <Input disabled={rowsOriginal.length > 0 ? false : true} onChange={(e) => search(e)}
                                  value={searchtxt}
                                  id="input-with-icon-adornment"
                                  startAdornment={
                                      <InputAdornment style={{ marginLeft:20 }} position="start">
                                      <SearchIcon />
                                      </InputAdornment>
                                  }
                              />
                          </FormControl>
                          </form>
                          </Grid>
                          <Grid item md={5} xs={12} style={{ marginTop:10 }}>
                          <FormControl style={{ width: "100%"}} >
                              <InputLabel style={{ paddingLeft: 25 }} id="simple-select-outlined-label">Search by</InputLabel>
                              <Select
                                  style={{ paddingLeft: 18 }} 
                                  disabled={rowsOriginal.length > 0 ? false : true}
                                  labelId="simple-select-outlined-label"
                                  id="simple-select-outlined"
                                  value={searchValue}
                                  label="Search by"
                                  onChange={(e) => onChangeSearchSelect(e)}
                              >
                                  {searchCell.map((cell, index)=>{
                                      const labelId = `enhanced-search-${index}`;
                                      return(
                                          <MenuItem key={labelId} value={cell.id}>{cell.label}</MenuItem>
                                      )
                                  })}
                              </Select>
                          </FormControl>
                          </Grid>
                      </Grid>
                    </TableCell>
                </TableRow>}
                {stableSort(readRows(), getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const rolllabelId = `enhanced-table-roll-${index}`;

                    return (
                      <React.Fragment key={rolllabelId}>
                        <TableRow
                          hover
                          style={{ opacity:1, height: 50 }}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected} 
                          >
                          <TableCell padding="checkbox">
                          {props.hideCheckBox ? null : <Checkbox
                                style={{ display: props.listOnly ? "none" : "normal"}} 
                                onClick={(event) => handleCheckBoxClick(event, row)}
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />}
                          </TableCell>
                          
                          {headCells.map((cell, cellIndex) => {
                              const labelCellId = `enhanced-table-cell-${cellIndex}` + row.id;
                              
                              if(cell.id === "created" || cell.id === "updated"){
                                return(
                                  row[cell.id] ? <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Typography  style={{ wordWrap: "break-word", fontSize: 11 }} variant="body2" >
                                      {new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' }).format(row[cell.id])}
                                    </Typography>
                                    <Typography  style={{ wordWrap: "break-word", fontSize: 11 }} variant="body2" >
                                      {new Intl.DateTimeFormat('en-GB', {hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }).format(row[cell.id])}
                                    </Typography>
                                  </TableCell> : <TableCell key={labelCellId} style={{ padding: 3 }} align="left"></TableCell>)
                              }

                              else if(cell.id === "customer_phone"){
                                return(
                                  row[cell.id] ? <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                  {row[cell.id]}
                                  </TableCell> : <TableCell key={labelCellId} style={{ padding: 3 }} align="left"></TableCell>)
                              }
                              else if(cell.id === "status"){

                                let _status = "";
                                if(row[cell.id] && row[cell.id] == "approved")
                                {
                                  _status = "Approved For Repair";
                                }
                                else if(row[cell.id] && row[cell.id] == "submit_for_approval")
                                {
                                  _status = "Submit for Approval";
                                }
                                else if(row[cell.id] && row[cell.id] == "seek_verification")
                                {
                                  _status = "Seek Verification";
                                }
                                else if(row[cell.id] && row[cell.id] == "approved_repair_detail")
                                {
                                  _status = "Approved Repair Detail";
                                }
                                else if(row[cell.id] && row[cell.id] == "cancelled")
                                {
                                  _status = "Cancelled";
                                }
                                else if(row[cell.id] && row[cell.id] == "rejected")
                                {
                                  _status = "Rejected";
                                }
                                else if(row[cell.id] && row[cell.id] == "closed")
                                {
                                  _status = "Closed";
                                }


                                return(
                                  row[cell.id] ? <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                  {_status}
                                  </TableCell> : <TableCell key={labelCellId} style={{ padding: 3 }} align="left"></TableCell>)
                              }

                              else if(cell.id === "fully_submitted"){
                                return(
                                  row[cell.id] ? <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                  {row[cell.id]}
                                  </TableCell> : <TableCell key={labelCellId} style={{ padding: 3 }} align="left">{"No"}</TableCell>)
                              }
                              else if(cell.id === "submit_user"){
                                return(
                                  row[cell.id] ? <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                  {row[cell.id].id}
                                  </TableCell> : <TableCell key={labelCellId} style={{ padding: 3 }} align="left">{"No"}</TableCell>)
                              }
                              else if(cell.id === "service_center_id"){

                                var _service_center_name = "";
                                var currentIndex = serviceCentre.map(val=>val.id).indexOf(row[cell.id]);
                                if(currentIndex !== -1 )
                                {
                                  _service_center_name = serviceCentre[currentIndex].name;
                                }

                                return(
                                  row[cell.id] ? <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                  {_service_center_name}
                                  </TableCell> : <TableCell key={labelCellId} style={{ padding: 3 }} align="left">{""}</TableCell>)
                              }
                             
                              
                              return(
                              <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                  <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{row[cell.id]}</Typography>
                                </Grid>
                              </TableCell>)
                          })}
                          {props.control && matches? 
                            <TableCell key={"control"} style={{ padding: 3 }} align="right">
                                <ControlButton 
                                  row={row}
                                  onClickPreview={(row)=> onClickPreview(row)} 
                                  onClickProofOfPayment={(row)=> onClickProofOfPayment(row)} 
                                  onClickMove={(row)=> onClickMove(row)} 
                                  onClickInfo={(row)=> onClickInfo(row)}
                                  onClickReceipt={(row)=> onClickReceipt(row)}
                                  onClickWarranty={(row)=> onClickWarranty(row)}
                                  onClickIncidentReport={(row)=> onClickIncidentReport(row)}
                                  onClickDownloadIR={(row)=> onClickDownloadIR(row)}
                                  onClickCheckOutReceipt={(row)=> onClickCheckOutReceipt(row)}
                                  onClickEdit={(row)=> onClickEdit(row)}
                                  onClickDelete={(row)=> onClickDelete(row)}
                                  onClickOpenFolder={(row)=> onClickOpenFolder(row)}
                                  {...props}
                                />
                          </TableCell> : null}

                            {matches ? null : 
                            <TableCell align="right">
                              <IconButton onClick={() => { 
                                  if(collapse === row.id){
                                    setCollapse(null)
                                  }
                                  else
                                  {
                                    setCollapse(row.id)
                                  }
                              }}>
                                {collapse === row.id? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell>}
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headCells.length+1+(props.control ? 1 : 0)}>
                              <Collapse in={collapse === row.id ? true : false } timeout="auto" unmountOnExit>
                                  <Box margin={1}>
                                      <ControlButton 
                                        row={row}
                                        onClickPreview={(row)=> onClickPreview(row)} 
                                        onClickProofOfPayment={(row)=> onClickProofOfPayment(row)} 
                                        onClickMove={(row)=> onClickMove(row)} 
                                        onClickInfo={(row)=> onClickInfo(row)}
                                        onClickReceipt={(row)=> onClickReceipt(row)}
                                        onClickWarranty={(row)=> onClickWarranty(row)}
                                        onClickIncidentReport={(row)=> onClickIncidentReport(row)}
                                        onClickDownloadIR={(row)=> onClickDownloadIR(row)}
                                        onClickCheckOutReceipt={(row)=> onClickCheckOutReceipt(row)}
                                        onClickEdit={(row)=> onClickEdit(row)}
                                        onClickDelete={(row)=> onClickDelete(row)}
                                        onClickOpenFolder={(row)=> onClickOpenFolder(row)}
                                        {...props}
                                      />
                                  </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    }) }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                    labelRowsPerPage={"Pages"}
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={props.headCells.length+1+(props.control ? 1 : 0)}
                    count={readRows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>

            </TableFooter>
        </Table>
        </TableContainer>
          
    </Grid>
  );
}

const mapDispatchToProps = dispatch => ({
  setCurrentUser: item => dispatch(setCurrentUser(item)),
  setItem: (item, user) => dispatch(setItem(item, user)),
  setProductItem: item => dispatch(setProductItem(item)),
  setCategoryItem: item => dispatch(setCategoryItem(item))
});

const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentStaff: selectCurrentStaff,
  productItems: selectProductItems,
  categoryItems: selectCategoryItems,
})

export default connect(mapStatetoProps, mapDispatchToProps)(CustomPaginationActionsTable);
