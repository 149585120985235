import React, { useMemo, useEffect } from "react";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress'
import app from "../../firebase/base";
import firebase from 'firebase/compat/app';
import { useHistory } from "react-router-dom"
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import {useDropzone} from 'react-dropzone'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


import { connect } from 'react-redux'
import {
  setCurrentUser
} from '../../redux/user/user.actions';

//styles
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#d8d8d8',
  borderStyle: 'dashed',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #d8d8d8',
  marginBottom: 8,
  marginRight: 8,
  width: 70,
  height: 70,
  padding: 4,
  boxSizing: 'border-box'
};


const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

var upload_count = 0;
var files_uploaded = [];

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://instaprotection.sg/">
      Instaprotection Pte. Ltd. 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    display:"flex",
    justifyContent: "center",
    padding: 20
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
});

function SignIn(props) {

  const classes = props.classes;
  const [userFill, setUserFill] = React.useState({
    first_name: "",
    last_name: "",
    status_users: "new_user",
    email: "",
    role:"staff", 
  });
  const [loading, setLoading] = React.useState(true);

  const [isFile, setIsFile] = React.useState(true);

  const [files, setFiles] = React.useState([]);

  let history = useHistory();


  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
      accept: 'image/*,application/pdf',
      multiple: true,
      maxFiles:5,
      onDrop: acceptedFiles => {
          setFiles(acceptedFiles.map(file => Object.assign(file, {
              preview: URL.createObjectURL(file)
          })));
      }
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  const thumbs = files.map((file, index) => {

    if(file.type === "application/pdf"){
        
          return(
              <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                  <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, marginBottom: 10}}>
                      <Typography variant="caption" noWrap={true}>{file.name}</Typography>
                  </Grid>
                  
                  <Grid style={thumb} key={file.name}>
                      <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%"}}>
                          <PictureAsPdfIcon color="primary" style={{ fontSize: 36}}/>
                      </Grid>
                  </Grid>
              </Grid>
            
          )
      }
      else{
          return(
              <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                  <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, marginBottom: 10}}>
                      <Typography variant="caption" noWrap={true}>{file.name}</Typography>
                  </Grid>
                  
                  <Grid style={thumb} key={file.name}>
                      <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%", overflow: 'hidden'}}>
                          <img
                          src={file.preview}
                          style={img}
                          alt={file.name}
                          />
                      </Grid>
                  </Grid>
              </Grid>
          )
      }
  });

  const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
  }), [
      isDragActive,
      isDragReject,
      isDragAccept,
  ]);

  const onClickClear = () => {
    acceptedFiles.length = 0;
    setFiles([])
  }

  const completedUpload = () =>{
    upload_count++;
    uploadFiles();
  };

  const handleUploadSuccess = (metadata, filename) => {

    firebase.storage().ref("user_ic")
    .child(metadata.name)
    .getDownloadURL().then((url) => {

        var postData = {
            name:filename,
            file_name: metadata.name,
            url: url,
            size: metadata.size,
            created: firebase.database.ServerValue.TIMESTAMP,
            type: metadata.contentType,
            full_path: metadata.fullPath,
            user: firebase.auth().currentUser.displayName
        };

        files_uploaded.push(postData)
        completedUpload()
    })
    
  }

  const saveData = () =>{

    let areaUUIDRef = firebase.database().ref("user_sequence" )

    areaUUIDRef.once('value', snapshot => {
      if(snapshot.exists()){
        let val = snapshot.val()

        let newUUID = Number(val.uuid)+1
        saveUser(newUUID)
        firebase.database().ref("user_sequence").update({ uuid: newUUID })
      }
      else{
        saveUser(1)
        firebase.database().ref("user_sequence").update({ uuid: 1 })
      }

    })

  }

  const saveUser = (uuid) => {

    userFill.id = "IP" + parseInt(uuid).toLocaleString('en-US', {minimumIntegerDigits: 6, useGrouping:false});
    userFill.phone = app.auth().currentUser.phoneNumber;
    userFill.created = firebase.database.ServerValue.TIMESTAMP;
    //userFill.user_ic = files_uploaded;

    var updates = {};
    updates['users/' + userFill.id] = userFill;
    
    firebase.database().ref().update(updates).then(()=>{
      acceptedFiles.length = 0;
      setFiles([])
      window.location.reload(false);
    });
      
  }

  const uploadFiles = () => {
    if(Array.from(acceptedFiles).length === upload_count){

      let areaUUIDRef = firebase.database().ref("user_sequence" )

      areaUUIDRef.once('value', snapshot => {
        if(snapshot.exists()){
          let val = snapshot.val()

          let newUUID = Number(val.uuid)+1
          saveUser(newUUID)
          firebase.database().ref("user_sequence").update({ uuid: newUUID })
        }
        else{
          saveUser(1)
          firebase.database().ref("user_sequence").update({ uuid: 1 })
        }

      })

        
    }else{
        
        var filename = Array.from(acceptedFiles)[upload_count].name;
        var upload_task = firebase.storage().ref("user_ic").child(new Date().getTime() +"_"+Array.from(acceptedFiles)[upload_count].name).put(acceptedFiles[upload_count]);
        upload_task.then((snapshot) => {
            handleUploadSuccess(snapshot.metadata, filename);
        }).catch((error)=>{
            console.log(error.message)
        })

    }
  }

  const handleSignUp = async (event) => {
    
    event.preventDefault();

    let userRef = app.database().ref("users");

    userRef.once('value', snapshot => {
      
      if(snapshot.exists()){
        var userRegistered = false;

        snapshot.forEach(child => {
          var itemsVal = child.val();

          if(itemsVal.phone === app.auth().currentUser.phoneNumber){
            userRegistered = true
          }
        })

        if(userRegistered){
          window.location.reload(false);
        }
        else
        {
          saveData();
        }

      }
      else
      {
        saveData();
      }

    })

  }

  const handleUserFillChange = (prop) => (event) => {
    setUserFill({ ...userFill, [prop]: event.target.value });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item component={Paper} elevation={0} style={{ width: 400, marginTop: 50, backgroundColor: "#f2f2f2" }}>
        <Grid style={{ display: 'flex', justifyContent: "space-between", height: 120, backgroundColor: "#e7e7e7", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
          
          <Grid>
            <Typography color="primary" variant='body1' style={{  marginLeft: 30, marginTop: 40, fontWeight: "bold"}}>
              New User
            </Typography>
            <Typography color="primary" variant='body2' style={{  marginLeft: 30 }}>
              Sign up to continue
            </Typography>
          </Grid>
          
          <img height="100%" src="assets/login/profile-img.png" alt="top-login-bg" />
        </Grid>
        <div className={classes.paper}>
          
          {loading ? 
          
          <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70, marginBottom: 70  }}>
              <CircularProgress 
                disableShrink
                color="primary" />
          </div>
          
          : 
          
          <form className={classes.form} onSubmit={handleSignUp}>
              <Grid spacing={2} container >
                <Grid item md={12} xs={12}>
                  <Typography variant='body2'>
                  First Name*
                  </Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    autoComplete='off'
                    fullWidth
                    id="firstName"
                    name="First Name"
                    size="small"
                    style={{ marginTop: 5}}
                    value={userFill["first_name"]}
                    onChange={handleUserFillChange("first_name")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography variant='body2'>
                  Last Name*
                  </Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    autoComplete='off'
                    fullWidth
                    id="lastName"
                    name="Last Name"
                    size="small"
                    style={{ marginTop: 5}}
                    value={userFill["last_name"]}
                    onChange={handleUserFillChange("last_name")}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Typography variant='body2'>
                  Email*
                  </Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    autoComplete='off'
                    fullWidth
                    id="email"
                    name="Email"
                    size="small"
                    style={{ marginTop: 5}}
                    value={userFill["email"]}
                    onChange={handleUserFillChange("email")}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Typography variant='body2'>
                  Service Center ID*
                  </Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    autoComplete='off'
                    fullWidth
                    id="service_center"
                    name="Service Center"
                    size="small"
                    style={{ marginTop: 5}}
                    value={userFill["service_center_id"]}
                    onChange={handleUserFillChange("service_center_id")}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="role">Role</InputLabel>
                    <Select
                    labelId="role"
                    id="role"
                    value={userFill["role"]}
                    required
                    label="Role"
                    onChange={handleUserFillChange("role")}
                    >
                    <MenuItem value={"staff"}>Staff</MenuItem>
                    <MenuItem value={"manager"}>Manager</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
              
            
              <Button
                style={{textTransform: 'none'}}
                fullWidth
                color="primary"
                variant="contained"
                className={classes.submit}
                disableElevation
                type="submit"
              >
              Register
              </Button>

              <Button
                style={{textTransform: 'none', width: "100%"}}
                fullWidth
                color="primary"
                disableElevation
                onClick={()=> app.auth().signOut().then(()=> { history.push("/") })}
              >
              Login with Another Phone Number
              </Button>

              <Grid style={{ display: 'flex', justifyContent: "center", marginTop: 20 }}>
              <img height="80" src="assets/logo/logo-black.png" alt="Insta Protection logo"/>
              </Grid>
              
              
              <Grid style={{ display: 'flex', justifyContent: "center", marginTop: 20 }}>
                <Copyright />
              </Grid>
          </form>}
        </div>
      </Grid>
    </Grid>);
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setCurrentUser: item => dispatch(setCurrentUser(item)),
});

export default withStyles(styles)(connect(null, mapDispatchToProps)(SignIn));