import React, { useEffect }  from "react";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import firebase from 'firebase/compat/app';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '../../modules/components/Dialog';
import moment from "moment";

import EW from './EW';
import RepairDetail from './RepairDetail';
import SC from './SC';
import SCRepairDetail from './SCRepairDetail';

import EWList from './EW/EW';
import ScreenCrack from './ScreenCrack/ScreenCrack';

import { updateDoc, serverTimestamp } from "firebase/firestore";

const styles = theme => ({
  root: {
    display:"flex",
    justifyContent: "center",
    padding: 20,
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
});

function SignIn(props) {

  const classes = props.classes;

  const [imei, setImei] = React.useState("");
  const [isImei, setIsImei] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [product, setProduct] = React.useState(null);
  const [claimButtonDisable, setClaimButtonDisable] = React.useState(true);
  const [ewClaimed, setEwClaimed] = React.useState(null);

  //Dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogDes, setDialogDes] = React.useState("");
  const [agreeButton, setAgreeButton] = React.useState(false);
  const [option, setOptions] = React.useState("");
  
  const [claimOption, setClaimOption] = React.useState("ew");

  useEffect(() => {

    setLoading(false)

  }, [props]);

  const handleInputChange = (event) => {
    setClaimOption(event.target.value);
  };

  const handleImei = (e) => {
    setImei(e.target.value.trim())
  }

  const handleSubmit = () => {

    setProduct(null)
    setIsImei(false)
    setLoading(true)

    if(imei.length > 12 ){
      setIsImei(false)

      if(claimOption === "ew"){
        let imeiRef = firebase.database().ref("imei");
  
        imeiRef.once('value', snapshot => {
  
          if(snapshot.exists()){
            var imeifound =null;
            snapshot.forEach(val => {
  
              if(Number(val.val().imei_no) === Number(imei)){
                  
                imeifound = val.val();
              }
                
            })
  
            if(imeifound)
            {
              let claimRef = firebase.database().ref("ew_claimed/" +imeifound.imei_no);
  
              claimRef.on('value', snapshot => {
                if(snapshot.exists()){
                  setEwClaimed(snapshot.val())
                }
                else{
                  setEwClaimed(null)
                }
                
                if(moment(imeifound.ew_service_plan_expired_date).isAfter(moment()))
                {
                  setClaimButtonDisable(false)
                }
                else
                {
                  setClaimButtonDisable(true)
                }

                setProduct(imeifound)
                setIsImei(false)
                setLoading(false)
              })
  
              
            }
            else
            {
              setIsImei(true)
              setLoading(false)
            }
           
          }
  
        })
      }
      else
      {
        let scRef = firebase.database().ref("screen_crack");
  
        scRef.once('value', snapshot => {
  
          if(snapshot.exists()){
            var imeifound =null;
            snapshot.forEach(val => {
  
              if(Number(val.val().imei_no) === Number(imei)){
                  
                imeifound = val.val();
              }
                
            })
  
            if(imeifound)
            {
              let claimRef = firebase.database().ref("sc_claimed/" +imeifound.imei_no);
  
              claimRef.on('value', snapshot => {
                if(snapshot.exists()){
                  setEwClaimed(snapshot.val())
                }
                else{
                  setEwClaimed(null)
                }

                if(moment(imeifound.sc_service_plan_expired_date).isAfter(moment()))
                {
                  setClaimButtonDisable(false)
                }
                else
                {
                  setClaimButtonDisable(true)
                }
  
                setProduct(imeifound)
                setIsImei(false)
                setLoading(false)
              })
  
              
            }
            else
            {
              setIsImei(true)
              setLoading(false)
            }
           
          }
  
        })
      }
      
    }
    else{
      setIsImei(true)
      setLoading(false)
    }
  }

  const renderComponent = () => {
      if(option === "ew"){
        return(<EW product={product} handleClose={handleClose}/>)
      }
      else if(option === "repair_details")
      {
        return(<RepairDetail product={product} ewClaimed={ewClaimed} handleClose={handleClose}/>)
      }
      if(option === "screen_crack"){
        return(<SC product={product} handleClose={handleClose}/>)
      }
      else if(option === "sc_repair_details")
      {
        return(<SCRepairDetail product={product} ewClaimed={ewClaimed} handleClose={handleClose}/>)
      }
      else if(option === "display_sc")
      {
        return(<ScreenCrack imei={product && product.imei_no ? product.imei_no : null} />)
      }
      else if(option === "display_ew")
      {
        return(<EWList imei={product && product.imei_no ? product.imei_no : null} />)
      }
      else
      {
        return(<Grid />)
      }
  }

  const handleClose = () => {
    setOpenDialog(false)
  };

  const handleSubmitClaim = () => {

    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Submit Claim")
    setOptions(claimOption)
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
    
  }

  const handleSubmitRepairDetails = () => {
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Submit Repair Details")
    setOptions(claimOption === "ew" ? "repair_details" : "sc_repair_details")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const handleDisplayEW = () => {
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Extended Warranty")
    setOptions(claimOption === "ew" ? "display_ew" : "display_sc")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const renderClaimButton = () => {

    let ClaimButton = true;
    let ScreenCrackClaim = false;
    let repairbuttonDisable = true;
    let rejected = false;

    if(ewClaimed)
    {
      var _ewClaimed = Object.values(ewClaimed);
      _ewClaimed.forEach(val =>{
      
        if(!val.repair_date)
        {
          ClaimButton = false;

          if(val.status === "approved"){
            repairbuttonDisable = false;
          }

         
        }

        if(val.status === "rejected"){
          rejected = true;
        }
      })

      if(_ewClaimed.length > 0){
        ScreenCrackClaim = true;
      }
    }

    if(ScreenCrackClaim && claimOption === "screen_crack" && ClaimButton){
      return null
    }
    
    if(!rejected){
      if(ClaimButton){
        return(<Button
          disabled={claimButtonDisable}
          style={{textTransform: 'none', width: "100%", marginTop: 10 }}
          fullWidth
          variant="contained"
          disableElevation
          onClick={()=> handleSubmitClaim()}
        >
          Claim Now
        </Button>)
      }
      else
      {
        return(<Button
          disabled={repairbuttonDisable}
          style={{textTransform: 'none', width: "100%", marginTop: 10 }}
          fullWidth
          variant="contained"
          disableElevation
          onClick={()=> handleSubmitRepairDetails()}
        >
        Submit Repair Details
      </Button>)
      }
    }
    
     
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Dialog 
          agreeTxt={"Yes"}
          disagreeTxt={"No"}
          description={dialogDes}
          title={dialogTitle}
          open={openDialog} 
          agreeButton={agreeButton}
          fullScreenDialog={fullScreenDialog}
          option={option}
          dialogLoading={dialogLoading}
          component={renderComponent()}
          handleClose={handleClose}/>
      <Grid item component={Paper} elevation={0} style={{ minWidth: 300, marginTop: 50, backgroundColor: "#f2f2f2" }}>
        <div className={classes.paper}>
          
        <form className={classes.form}>
              
              {loading ? 
              <Grid>
                <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', padding: 80  }}>
                  <CircularProgress 
                    disableShrink
                    color="primary" />
                </div>
              </Grid>
              :
              <Grid>
                <Typography variant='body2' style={{ marginTop: 10, marginBottom: 10}}>
                  Please input IMEI No. to begin search
                </Typography>

                
                <FormControl variant="outlined" fullWidth>
                  <Select
                    labelId="option-simple-select-outlined-label"
                    id="option-simple-select-outlined"
                    value={claimOption}
                    onChange={handleInputChange}
                  >
                    <MenuItem value={"ew"}>Extended Warranty</MenuItem>
                    <MenuItem value={"screen_crack"}>Screen Crack</MenuItem>
                  </Select>

                  <TextField
                    style={{ marginTop: 10 }}
                      error={isImei}
                      variant="outlined"
                      margin="normal"
                      type="tel"
                      required
                      fullWidth
                      autoComplete='off'
                      id="imei"
                      name="imei"
                      size="small"
                      value={imei}
                      onChange={handleImei}
                    />
                  {isImei ? <Typography variant='body2' color="error" style={{ marginBottom: 10, fontSize: 11 }}>
                  Record no found
                </Typography> : null}

                </FormControl>

                <Button
                  style={{textTransform: 'none', width: "100%", marginTop: 10 }}
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={()=> handleSubmit()}
                >
                Search
                </Button>

                {product ? <Grid style={{ marginTop: 20 }}>

                  <Divider style={{  opacity: .2 }}/>
                  <Typography variant='body2' style={{ marginTop: 10, marginBottom: 10}}>
                    {"IMEI : " + (product.imei_no ? product.imei_no : "-")}
                  </Typography>
                  <Typography variant='body2' style={{ marginTop: 10, marginBottom: 10}}>
                    {"IMEI 2 : " + (product.imei_no_2 ? product.imei_no_2 : "-")}
                  </Typography>
                  <Typography variant='body2' style={{ marginTop: 10, marginBottom: 10}}>
                    {"Product Brand : " + (product.physical_device_brand ? product.physical_device_brand : "-")}
                  </Typography>
                  <Typography variant='body2' style={{ marginTop: 10, marginBottom: 10}}>
                    {"Product Model : " + (product.physical_device_model_no ? product.physical_device_model_no : "-")}
                  </Typography>
                  {claimOption === "ew" ? <Typography variant='body2' style={{ marginTop: 10, marginBottom: 10}}>
                    {"Coverage Start Date : " + (product.ew_service_plan_start_date ? 
                      moment(product.ew_service_plan_start_date).format("DD-MM-YYYY") : "-")}
                  </Typography> :  null }

                  {claimOption === "ew" ? <Typography variant='body2' style={{ marginTop: 10, marginBottom: 10}}>
                    {"Coverage End Date : " + (product.ew_service_plan_expired_date ? 
                      moment(product.ew_service_plan_expired_date).format("DD-MM-YYYY") : "-")}
                  </Typography> :  null }

                  {claimOption === "screen_crack" ? <Typography variant='body2' style={{ marginTop: 10, marginBottom: 10}}>
                    {"Coverage Start Date : " + (product.sc_service_plan_start_date ? 
                      moment(product.sc_service_plan_start_date).format("DD-MM-YYYY") : "-")}
                  </Typography> :  null }

                  {claimOption === "screen_crack" ? <Typography variant='body2' style={{ marginTop: 10, marginBottom: 10}}>
                    {"Coverage End Date : " + (product.sc_service_plan_expired_date ? 
                      moment(product.sc_service_plan_expired_date).format("DD-MM-YYYY") : "-")}
                  </Typography> :  null }
                  {renderClaimButton()}
                  {ewClaimed ? 
                  <Grid style={{ marginTop: 10 }}>
                    <Button
                      style={{textTransform: 'none', width: "100%", marginTop: 10 }}
                      fullWidth
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={()=> handleDisplayEW()}
                    >
                    History
                    </Button>
                  </Grid>
                  : 
                  <Typography variant='body2' style={{ marginTop: 20, marginBottom: 10}}>
                    No Claim Record
                  </Typography>
                  }
                </Grid> : null }

              </Grid>}
          </form>
        </div>
      </Grid>

    </Grid>);
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);